import React, {useEffect, useRef, useState} from 'react';
import {Spinner, Button, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import Main, {
  Time,
  Subtitle,
  Videoplayer,
  Skip,
  Bumper,
  Sponsored,
  SurveyForm,
  SubmitButton,
  PreTestForm,
  PreTestQuestionBlock,
  PreTestFormCheckContainer,
  PreTestStyledLabel,
} from './elements/VideoPlayer';
import {useStore} from '../../store';
import Play from '../../assets/icons/play-solid.png';
import Replay from '../../assets/icons/replay.png';
import {EarnCmeButton, EarnCmeRegisterButton} from '../AssessmentButton/style';
import {submitPreTestAnswers} from '../../api/cme';
// import { Navigation } from 'reactour';
// eslint-disable-next-line no-unused-vars

const VideoPlayer = observer(({videoId, thumbnail, freeVideoLimitExceed, watchVideoCount}) => {
  const ref = useRef();
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const [playerLoadInitiated, setPlayerLoadInitiated] = useState(false);
  const [videoWatch, setVideoWatch] = useState(true);
  const {videoStore, accountStore, adsStore, cmeStore} = useStore();
  const {
    setVideoPlayer,
    videoTimeExceeded,
    setVideoTimeExceeded,
    videoPause,
    setVideoPause,
    setRewatch,
    videoPlayer,
    noFreeMin,
    hasWatched,
    bumperEnded,
    showBumperSkip,
    setBumperEnded,
    setShowBumperSkip,
    showAd,
    bumperCounter,
    setBumperCounter,
    setPreTestVideoId,
    preTestQuestions,
    video,
    preTestFlowEnabled,
    setPreTestFlowEnabled,
    freeCme,
  } = videoStore;

  const {account} = accountStore;

  const {
    bumperAd,
    userTrackingAds,
    showBumper,
    handleShowBumper,
    updateAdHistory,
    survey,
    addSurveyResponse,
  } = adsStore;

  const {setPreTestCme, preTestCme, setPreTestSubmitted, setPreTestProgress, setUserCme} = cmeStore;

  const [showSkip, setShowSkip] = useState(false);
  const [afterSkip, setAfterSkip] = useState(false);
  const [suveySkip, setSurveySkip] = useState(false);
  const [surveyResponse, setSurveyResponse] = useState([]);

  const [currentPreTestQuestionIndex, setCurrentPreTestQuestionIndex] = useState(0);
  const [preTestOverMessage, setPreTestNoAnswerMessage] = useState('');
  const [currentPretTestAnswer, setCurrentPretTestAnswer] = useState('');
  const [preTestAnswers, setPreTestAnswers] = useState([]);
  const [selectedPreTestChoice, setSelectedPreTestChoice] = useState('');

  const history = useHistory();
  const location = useLocation();

  // const removeAllPlayers = () => {
  //   const players = window.videojs?.getAllPlayers();
  //   if (players?.length && videoPlayer) {
  //     for (let i = 0; i < players?.length; i += 1) {
  //       if (players[i]?.id_ !== undefined) {
  //         players[i].dispose();
  //       }
  //     }
  //   }
  // };

  const removeAllPlayers = () => {
    // New function replacing the old removeAllPlayers() because its causing video player error boundary issue when selecting a video from My CME page.
    // Old function is commented above
    const players = window.videojs?.getAllPlayers();
    if (players?.length && videoPlayer) {
      for (let i = 0; i < players?.length; i += 1) {
        if (players[i]?.id_ !== videoPlayer.id_) {
          players[i].dispose();
        }
      }
    }
  };

  useEffect(() => {
    removeAllPlayers(); // This line in the use effect is causing video player error boundary issue when selecting a video from My CME page. So changed the function as mentioned above.
    setShowSkip(false);
    setAfterSkip(false);
    setPlayerLoadInitiated(false);
    setBumperEnded(false);
    setShowBumperSkip(false);
    setSurveySkip(false);
    // eslint-disable-next-line
  }, [videoId]);

  useEffect(() => {
    if (bumperAd !== null && afterSkip) {
      removeAllPlayers();
      setAfterSkip(false);
      setPlayerLoadInitiated(false);
    }

    if (!playerLoadInitiated) {
      setPlayerLoadInitiated(true);
      if (showAd && bumperAd !== null && showBumper && bumperAd?.brightcove_id) {
        setVideoPlayer(ref, bumperAd?.brightcove_id, true)
          .then(() => {
            setPlayerLoaded(true);
            userTrackingAds({
              ad_id: bumperAd.id,
              impression_type: 'view',
              impression_source: window.location.pathname,
              device: window.innerWidth <= 800 && window.innerHeight <= 600 ? 'mobile' : 'desktop',
            });
          })
          .catch(() => {
            setPlayerLoaded(false);
          });
      } else if (videoId && !noFreeMin && !freeVideoLimitExceed && videoWatch) {
        setVideoPlayer(ref, videoId)
          .then(() => {
            setPlayerLoaded(true);
          })
          .catch(() => {
            setPlayerLoaded(false);
          });
      }
    }
    if (videoTimeExceeded) {
      setPlayerLoadInitiated(false);
    } // eslint-disable-next-line
  }, [
    ref,
    playerLoaded,
    videoId,
    setVideoPlayer,
    playerLoadInitiated,
    videoTimeExceeded,
    noFreeMin,
    freeVideoLimitExceed,
    videoWatch,
    showBumper,
    bumperAd,
  ]);

  useEffect(() => {
    if (
      account?.subscription?.name?.includes('Free') &&
      !account.access &&
      (freeVideoLimitExceed || (account?.id && watchVideoCount < 5))
    ) {
      setVideoWatch(false);
    }
    // eslint-disable-next-line
  }, [account?.subscription, freeVideoLimitExceed, account?.id, watchVideoCount]);

  useEffect(() => {
    if (!bumperAd?.brightcove_id) {
      setTimeout(() => {
        setShowSkip(true);
      }, 5000);
    }
    // eslint-disable-next-line
  }, [bumperAd]);

  const [counter, setCounter] = useState(bumperAd?.is_skip ? 5 : 10);

  useEffect(() => {
    if (!bumperAd?.brightcove_id && showBumper && bumperAd !== null) {
      setTimeout(() => {
        handleShowBumper(false);
        setPlayerLoadInitiated(false);
      }, 10000);
    }
    setCounter(bumperAd?.is_skip ? 5 : 10);
    setBumperCounter(bumperAd?.is_skip ? 5 : 10);
    // eslint-disable-next-line
  }, [videoId]);

  useEffect(() => {
    if (bumperAd?.brightcove_id) {
      if (bumperEnded) {
        handleShowBumper(false);
        setPlayerLoadInitiated(false);
      }
    }
    // eslint-disable-next-line
  }, [bumperEnded]);

  const adRef = useRef();

  useEffect(() => {
    if (bumperAd !== null) {
      try {
        const adObserver = new IntersectionObserver(([entry]) => {
          if (entry?.isIntersecting) {
            userTrackingAds({
              ad_id: bumperAd.id,
              impression_type: 'view',
              impression_source: window.location.pathname,
              device: window.innerWidth <= 800 && window.innerHeight <= 600 ? 'mobile' : 'desktop',
            });
          }
        });
        adObserver?.observe(adRef?.current);
        return () => {
          adObserver?.disconnect();
        };
      } catch {
        return true;
      }
    }
    return true;
    // eslint-disable-next-line
  }, [adRef]);

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    }
  }, [counter]);

  useEffect(() => {
    setCurrentPreTestQuestionIndex(0);
    setCurrentPretTestAnswer('');
  }, [preTestQuestions]);

  useEffect(() => {
    setSelectedPreTestChoice(null);
    setCurrentPretTestAnswer('');
  }, [currentPreTestQuestionIndex]);

  const handleBumperSkip = is_bumper_skipped => {
    updateAdHistory(is_bumper_skipped, window.location.pathname);
  };

  const handleSurveySubmit = () => {
    if (surveyResponse.length > 0) {
      setSurveySkip(true);
      setPlayerLoadInitiated(false);
      handleShowBumper(false);
      userTrackingAds({
        ad_id: null,
        impression_type: 'view',
        impression_source: window.location.pathname,
        device: window.innerWidth <= 800 && window.innerHeight <= 600 ? 'mobile' : 'desktop',
        ad_type: 'survey',
        is_answered: true,
        survey_id: survey.id,
      });
      addSurveyResponse(survey.id, JSON.stringify(surveyResponse));
    }
  };
  const handleSurveySkip = () => {
    setSurveySkip(true);
    setPlayerLoadInitiated(false);
    handleShowBumper(false);
    userTrackingAds({
      ad_id: null,
      impression_type: 'view',
      impression_source: window.location.pathname,
      device: window.innerWidth <= 800 && window.innerHeight <= 600 ? 'mobile' : 'desktop',
      ad_type: 'survey',
      survey_id: survey.id,
    });
  };

  const handleChange = e => {
    const {value, checked} = e.target;

    if (checked) {
      setSurveyResponse([...surveyResponse, value]);
    } else {
      const index = surveyResponse.findIndex(el => el === value);
      const array = [...surveyResponse];
      if (index > -1) {
        array.splice(index, 1);
      }
      setSurveyResponse(array);
    }
  };

  const handleStartCme = () => {
    setPreTestCme(true);
    setPreTestVideoId(videoId);
  };

  const handleChoiceSelection = value => {
    setSelectedPreTestChoice(value);
    setCurrentPretTestAnswer(value);
  };

  const validateSubmission = async answers => {
    const incompleteQuestions = preTestQuestions.filter(question => {
      return !answers.find(answer => answer.id === question.id);
    });

    if (incompleteQuestions.length > 0) {
      setPreTestNoAnswerMessage('Please answer all questions before submitting.');
      return;
    }

    // All questions are answered; proceed to submit
    setPreTestNoAnswerMessage('');
    setVideoPause(false);
  };

  const handleNext = () => {
    if (currentPretTestAnswer) {
      const currentPreTestQuestion = preTestQuestions[currentPreTestQuestionIndex];
      const currentPreTestProgress = ((currentPreTestQuestionIndex + 1) / preTestQuestions.length) * 100;
      setPreTestProgress(currentPreTestProgress);
      setPreTestAnswers(prevAnswers => {
        const updatedAnswers = prevAnswers.filter(answer => answer.id !== currentPreTestQuestion.id);
        updatedAnswers.push({
          id: currentPreTestQuestion.id,
          question: currentPreTestQuestion.question,
          answer: currentPretTestAnswer,
        });
        return updatedAnswers;
      });
      if (currentPreTestQuestionIndex < preTestQuestions.length - 1) {
        setCurrentPreTestQuestionIndex(currentPreTestQuestionIndex + 1);
        setCurrentPretTestAnswer('');
        setSelectedPreTestChoice(null);
        setPreTestNoAnswerMessage('');
      }
    } else {
      setPreTestNoAnswerMessage('Please provide a response before proceeding to the next question.');
    }
  };
  const handlePreTestSubmit = async () => {
    if (currentPretTestAnswer) {
      const currentPreTestQuestion = preTestQuestions[currentPreTestQuestionIndex];

      // Create updated answers locally
      const updatedAnswers = preTestAnswers.filter(answer => answer.id !== currentPreTestQuestion.id);
      updatedAnswers.push({
        id: currentPreTestQuestion.id,
        question: currentPreTestQuestion.question,
        answer: currentPretTestAnswer,
      });

      validateSubmission(updatedAnswers);
      setPreTestAnswers(updatedAnswers);

      try {
        const response = await submitPreTestAnswers(updatedAnswers, video.organizationCME.id);
        if (response.data && response.data.status) {
          setVideoPause(false);
          if (videoPlayer) {
            videoPlayer.play();
          }
          setUserCme(response.data.userCme);
        } else {
          setVideoPause(true);
          videoPlayer.pause();
        }
        setPreTestSubmitted(true);
        setPreTestCme(false);
        setPreTestFlowEnabled(false);
      } catch (error) {
        setVideoPause(true);
        videoPlayer.pause();
      }
    } else {
      setPreTestNoAnswerMessage('Please provide a response before submitting.');
    }
  };

  const isLastQuestion = currentPreTestQuestionIndex === preTestQuestions.length - 1;

  const currentPreTestQuestion = preTestQuestions[currentPreTestQuestionIndex];

  return (
    <Videoplayer>
      {!playerLoaded && !noFreeMin && videoWatch && (
        <Main>
          <div className="spinner-wrapper w-100 h-100 d-flex align-items-center justify-content-center">
            <Spinner animation="grow" role="status" variant="secondary">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        </Main>
      )}
      {freeVideoLimitExceed &&
      !account?.access &&
      account?.subscription &&
      account?.subscription?.name?.includes('Free') &&
      !hasWatched ? (
        <Main>
          <img className="position-absolute w-100 h-100" src={thumbnail} ref={ref} alt="Video thumbnail" />
          <div className="lock-wrapper">
            <Link to={account?.id ? '/profile/plan-update' : '/register'} className="lock-link">
              <i className="fas fa-lock-alt lock-icon" />
            </Link>
            <Subtitle className="py-3">Upgrade your plan today to watch unlimited videos.</Subtitle>
            <p>{watchVideoCount < 5 ? watchVideoCount : '5'} / 5 videos watched</p>
            <Row>
              <Link to={account?.id ? '/profile/plan-update' : '/register'} className="register paused">
                <Button className="mx-3 register-btn">Upgrade Now</Button>
              </Link>
            </Row>
          </div>
        </Main>
      ) : (
        [
          noFreeMin ? (
            <Main>
              <img className="position-absolute w-100 h-100" src={thumbnail} ref={ref} alt="Video thumbnail" />
              <div className="lock-wrapper">
                <Link to="/login" className="lock-link">
                  <i className="fas fa-lock-alt lock-icon" />
                </Link>
              </div>
            </Main>
          ) : (
            [
              videoTimeExceeded ? (
                <Main>
                  <img className="position-absolute w-100 h-100" src={thumbnail} ref={ref} alt="Video thumbnail" />
                  <div className="lock-wrapper">
                    <Time>Finish this video</Time>
                    <Subtitle>Register today to watch the full video.</Subtitle>
                    <Row className="py-4">
                      <Button
                        className="watch-btn"
                        onClick={() => {
                          setVideoTimeExceeded(false);
                          setRewatch(true);
                        }}
                      >
                        Rewatch video
                        <img src={Replay} className="pl-3 pr-1 watch-icon" alt="replay" />
                      </Button>
                      <Link to={account?.id ? '/profile/plan-update' : '/register'} className="register paused">
                        <Button className="mx-3 register-btn">Subscribe Now</Button>
                      </Link>
                    </Row>
                  </div>
                </Main>
              ) : (
                <div className="video-screen">
                  {survey !== null && survey.id && !bumperAd?.id && !suveySkip ? (
                    <Main>
                      <div className="lock-wrapper">
                        <div className="question" dangerouslySetInnerHTML={{__html: survey.question}} />

                        <div className="row w-100 justify-content-center">
                          <div className="col-8 text-left">
                            <SurveyForm>
                              <div>
                                {survey?.options?.map((option, index) => (
                                  <label htmlFor={`option_${index}`} className="shadow bg-body-tertiary rounded">
                                    <input
                                      type="checkbox"
                                      id={`option_${index}`}
                                      onChange={handleChange}
                                      value={option}
                                    />{' '}
                                    {option}
                                  </label>
                                ))}
                              </div>

                              <SubmitButton onClick={handleSurveySubmit} type="button">
                                Submit Survey
                              </SubmitButton>
                            </SurveyForm>
                          </div>
                        </div>
                      </div>
                      <Sponsored>
                        <Skip
                          onClick={() => {
                            handleSurveySkip();
                          }}
                        >
                          Skip Survey
                        </Skip>
                      </Sponsored>
                    </Main>
                  ) : (
                    <>
                      {showAd && bumperAd !== null && showBumper && !bumperAd?.brightcove_id ? (
                        <Bumper>
                          <img src={bumperAd?.ad_asset} alt="" ref={adRef} />
                          <Sponsored>
                            <small>Sponsored</small>
                            {bumperAd?.is_skip && showSkip && (
                              <Skip
                                onClick={() => {
                                  handleShowBumper(false);
                                  setPlayerLoadInitiated(false);
                                  handleBumperSkip(true);
                                }}
                              >
                                Skip Ad
                              </Skip>
                            )}
                            {(!showSkip || !bumperAd?.is_skip) && <Skip>{counter}</Skip>}
                          </Sponsored>
                        </Bumper>
                      ) : (
                        <>
                          <div ref={ref} />
                          {showAd && showBumper && bumperAd?.brightcove_id && playerLoaded && (
                            <Sponsored>
                              <small>Sponsored</small>
                              {showBumperSkip && bumperAd.is_skip && (
                                <Skip
                                  onClick={() => {
                                    handleShowBumper(false);
                                    setAfterSkip(true);
                                    handleBumperSkip();
                                    setPlayerLoadInitiated(false);
                                  }}
                                >
                                  Skip Ad
                                </Skip>
                              )}
                              {!showBumperSkip && bumperCounter && <Skip>{bumperCounter}</Skip>}
                            </Sponsored>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {videoPause ? (
                    <Main className="pausedContainer">
                      <img className="position-absolute w-100 h-100" src={thumbnail} alt="Video thumbnail" />
                      <div className="pretest-lock-wrapper">
                        {(() => {
                          if (freeCme) {
                            if (preTestCme) {
                              if (videoPlayer) {
                                videoPlayer.pause();
                              }
                              return (
                                <>
                                  <div className="pre-test-form">
                                    <PreTestForm>
                                      <div className="pre-test-question">
                                        <h1 className="">
                                          <strong>
                                            Question {currentPreTestQuestion?.id} of {preTestQuestions.length}
                                          </strong>
                                        </h1>
                                        <PreTestQuestionBlock className="pre-test-question-block">
                                          <h1>{currentPreTestQuestion.question}</h1>

                                          {/* Handle 'choice' type widget */}
                                          {currentPreTestQuestion.widget.type === 'choice' && (
                                            <PreTestFormCheckContainer className="form-choice-container">
                                              <div className="form-check-container">
                                                {currentPreTestQuestion.widget.choices.map(option => (
                                                  <PreTestStyledLabel
                                                    key={option}
                                                    htmlFor={option}
                                                    selected={selectedPreTestChoice === option}
                                                    onClick={() => handleChoiceSelection(option)}
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      value={option}
                                                      id={option}
                                                      checked={selectedPreTestChoice === option}
                                                      name={`choiceAnswer-${currentPreTestQuestion.id}`}
                                                      onChange={() => setCurrentPretTestAnswer(option)}
                                                    />
                                                    {option}
                                                  </PreTestStyledLabel>
                                                ))}
                                              </div>
                                            </PreTestFormCheckContainer>
                                          )}

                                          <div style={{width: '100%'}}>
                                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                              {isLastQuestion ? (
                                                <Button onClick={handlePreTestSubmit} className="mt-3">
                                                  Submit Pre Test
                                                </Button>
                                              ) : (
                                                <Button onClick={handleNext} className="mt-3">
                                                  Next
                                                </Button>
                                              )}
                                            </div>
                                          </div>

                                          {preTestOverMessage && (
                                            <p className="pre-test-message">{preTestOverMessage}</p>
                                          )}
                                        </PreTestQuestionBlock>
                                      </div>
                                    </PreTestForm>
                                  </div>
                                </>
                              );
                            }
                            if (preTestFlowEnabled && account?.id) {
                              // User is logged in and freeCme is true
                              if (videoPlayer) {
                                videoPlayer.pause();
                              }
                              return (
                                <>
                                  <Time>Earn CME</Time>
                                  <Subtitle>
                                    Click <strong>Start CME</strong> to begin earning CME
                                  </Subtitle>
                                  <Row className="py-4">
                                    <EarnCmeButton onClick={handleStartCme}>
                                      <span>Start CME</span>
                                    </EarnCmeButton>
                                    {/* Uncomment this section if needed */}
                                    {/* <Link to="/profile/plan-update" className="register paused">
                                            <Button className="mx-3 register-btn">Upgrade Now</Button>
                                        </Link> */}
                                  </Row>
                                </>
                              );
                            }
                            if (preTestFlowEnabled) {
                              if (videoPlayer) {
                                videoPlayer.pause();
                              }
                              // User is not logged in but freeCme is true
                              return (
                                <>
                                  <Time>Earn CME</Time>
                                  <Subtitle>Login or register to earn CME.</Subtitle>
                                  <Row className="py-4">
                                    <EarnCmeRegisterButton
                                      // as="a"
                                      // href="/login"
                                      onClick={() => {
                                        setVideoPause(true);
                                        history.push({
                                          pathname: '/login',
                                          state: {from: location.pathname}, // Save the current video URL
                                        });
                                        // Additional logic for handling pretest can be added here
                                      }}
                                    >
                                      Log In Now
                                    </EarnCmeRegisterButton>
                                    {/* Uncomment this section if needed */}
                                    {/* <Link to="/profile/plan-update" className="register paused">
                                            <Button className="mx-3 register-btn">Upgrade Now</Button>
                                        </Link> */}
                                  </Row>
                                </>
                              );
                            }
                            setVideoPause(false);
                            videoPlayer?.play();
                            return null;
                          }
                          return (
                            <>
                              <Time>Keep Learning</Time>
                              <Subtitle>Register today to watch the full video.</Subtitle>
                              <Row className="py-4">
                                <Button
                                  className="watch-btn"
                                  onClick={() => {
                                    setVideoPause(false);
                                    videoPlayer?.play();
                                  }}
                                >
                                  Keep watching
                                  <img src={Play} className="pl-3 pr-1 watch-icon" alt="play" />
                                </Button>
                                <Link
                                  to={account?.id ? '/profile/plan-update' : '/register'}
                                  className="register paused"
                                >
                                  <Button className="mx-3 register-btn">Subscribe Now</Button>
                                </Link>
                              </Row>
                            </>
                          ); // Fallback in case neither condition is met
                        })()}
                      </div>
                    </Main>
                  ) : (
                    [
                      account?.id &&
                      watchVideoCount < 5 &&
                      account?.subscription?.name?.includes('Free') &&
                      !account?.access &&
                      !videoWatch &&
                      !video.organizationCME?.free_cme ? (
                        <Main className="pausedContainer">
                          <img className="position-absolute w-100 h-100" src={thumbnail} alt="Video thumbnail" />
                          <div className="lock-wrapper">
                            <Time>Keep Learning</Time>
                            <Subtitle>Upgrade your plan today to watch unlimited videos.</Subtitle>
                            <span className="">{watchVideoCount} / 5 videos watched</span>
                            <Row className="py-4">
                              <Button
                                className="watch-btn"
                                onClick={() => {
                                  setVideoWatch(true);
                                }}
                              >
                                Watch Now
                                <img src={Play} className="pl-3 pr-1 watch-icon" alt="play" />
                              </Button>
                              <Link to={account?.id ? '/profile/plan-update' : '/register'} className="register paused">
                                <Button className="mx-3 register-btn">Upgrade Now</Button>
                              </Link>
                            </Row>
                          </div>
                        </Main>
                      ) : null,
                    ]
                  )}
                </div>
              ),
            ]
          ),
        ]
      )}
    </Videoplayer>
  );
});
VideoPlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  freeVideoLimitExceed: PropTypes.bool,
  watchVideoCount: PropTypes.number,
};

export default VideoPlayer;
