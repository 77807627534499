/* eslint-disable camelcase */
// @flow
import _ from 'lodash';
import {makeAutoObservable, runInAction} from 'mobx';
import {getEducations, getInstitutions, getProfessions, getSpecialties} from '../../api/course';
// eslint-disable-next-line import/no-cycle
import {getAccount, setAccount} from '../../api/account';
import {partialUpdate} from '../../api/auth';
import {
  completeAccountRegistration,
  completePlan,
  completeSpecialties,
  createAccessEmail,
  forgotPassword,
  login,
  logout as logoutUser,
  registerEmail,
  resetPassword,
  socialConnect,
  socialLogin,
  verifyAccessEmail,
} from '../../api/index';
import logSession from '../../api/session';
import {requestContent} from '../../api/video';
import history from '../../history';
import {trackUserEmail} from '../../hubspot';
import SocketClient from '../../socketClient';
import {setAmplitudeUserId} from '../../utils/Amplitude';
import LocalStorageService from '../../utils/LocalStorageService';
import type {Price, USER_ROLE, CreateSubscriptionBody} from '../../utils/types';
import AccountStore from '../account/accountStore';
import ErrorStore from '../error/errorStore';
import * as Api from '../../api/subscription';

type RegistrationType =
  | 'email'
  | 'profession'
  | 'specialty'
  | 'plan'
  | 'payment'
  | 'account'
  | 'training'
  | 'institution'
  | 'subspecialty'
  | 'medical-school'
  | 'residency-program'
  | 'referrel'
  | 'content'
  | 'review'
  | '/access/account';
type Profession = {
  id: number,
  name: string,
  display_name?: string,
  specialty_type?: 'common' | 'other' | 'education',
  metadata?: {
    tags?: {
      content_interested?: boolean,
      institution?: boolean,
      educational_program?: boolean,
      level_of_training?: {
        list?: any[],
      },
      medical_school?: boolean,
      residency_program?: boolean,
      specialty?: boolean,
      sub_specialty?: boolean,
      profession?: boolean,
    },
  },
};

type SurgicalOrMedical = 'surgical' | 'medical';
type Specialty = {
  id: number,
  name: string,
  display_name?: string,
  type: 'common' | 'other' | 'education',
  surgical_or_medical?: SurgicalOrMedical[],
  videoCount?: number,
};

type Institution = {
  id: number,
  name: string,
};

type RegistrationForm = {
  email: string,
  firstName?: string,
  lastName?: string,
  password?: string,
  licenseNumber?: string,
  hospital?: string,
  speciality?: string,
  subSpeciality?: string,
  profession_id?: number,
  specialty_ids?: [],
  campaignCode?: string,
  extraData?: {
    other_profession?: string,
    other_specialty?: string,
  },
  profession_specialty: Boolean,
};
type AccessUser = {
  access_name: string,
  access_period_days: number,
  ads: boolean,
  cme: boolean,
  end_date: Date,
  notes: boolean,
  plan_name: string,
  start_date: Date,
  transcripts: boolean,
  content_based: boolean,
};
type User = {
  id: number,
  email: string,
  first_name: string,
  last_name: string,
  sns_id: string,
  cleeng_id: string,
  stripe_id: string,
  is_premium: boolean,
  is_banned: boolean,
  reason_for_ban: string,
  banned_at: Date,
  referrer: string,
  contact: string,
  is_used_coupon: boolean,
  redirect_page: string,
  daily_streak: number,
  weekly_streak: number,
  most_watched_specialty: {
    name: string,
    id: number,
  },
  cme_goal: number,
  goal_year: number,
  last_login_date: Date,
  is_registration_completed: boolean,
  is_subscribed: boolean,
  is_verified: boolean,
  profession_id: number,
  specialty_id: number,
  specialty_ids: [],
  extra_data: {
    other_profession: string,
    other_specialty: string,
  },
  profession_specialty: boolean,
  interest_answered: boolean,
  profession: Profession,
  access: [AccessUser],
  role: USER_ROLE,
};
class AuthStore {
  accountStore: AccountStore;

  errorStore: ErrorStore;

  loginResponseText = null;

  loginEmailResponseText = null;

  socialConnectionStatus = false;

  socialConnectionStatusText = null;

  registeredEmail = null;

  emailRegistered = true;

  registeredEmailResponseText = null;

  verifiedEmailResponseText = null;

  gotoLogin = false;

  verifiedStatus = true;

  createdStatus = false;

  accessCode = '';

  userId = null;

  forgotPasswordResponseText = null;

  resetPasswordStatus = false;

  isGoogleConnected = false;

  isFacebookConnected = false;

  selectedPrice: Price | null = null;

  selectedGiftCardOptions: Price[] | null = null;

  selectedProduct = null;

  selectedGiftCard = null;

  isSelectGiftCard = true;

  socialEmail = '';

  registrationForm: RegistrationForm = {
    email: '',
    firstName: '',
    lastName: '',
    licenseNumber: '',
    hospital: '',
    specialty_id: '',
    subSpecialty: '',
    password: '',
    campaignCode: null,
  };

  isCompleteRegistration = false;

  user: User = {};

  professionNext = [];

  profession_specialtyType = '';

  accountRegistering = false;

  registrationError = null;

  activeRegistrationStep: RegistrationType = 'plan';

  professionList: Profession[] = [];

  specialtyList: Specialty[] = [];

  institutionList: Institution[] = [];

  educationList: Specialty[] = [];

  accessStatus = null;

  isContentRequestSuccess = false;

  loading = false;

  showUpdateModal = false;

  constructor({errorStore, accountStore}) {
    this.accountStore = accountStore; // Store that can resolve account.
    this.errorStore = errorStore; // Store that can resolve account.
    makeAutoObservable(this);
  }

  logUserSession = async () => {
    try {
      await logSession();
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch All professions */
  getAllLists = async (cb: () => {}) => {
    try {
      const [professionsResp, specialtysResp, institutionsResp] = await Promise.all([
        getProfessions(),
        getSpecialties(),
        getInstitutions(),
      ]);
      const educationResp = await getEducations();
      const {professions} = professionsResp?.data;
      const {specialties} = specialtysResp?.data;
      const {institutions} = institutionsResp?.data;
      const educations = educationResp?.data.specialties;
      runInAction(() => {
        this.professionList = professions.sort((a, b) => a.id - b.id);
        this.specialtyList = specialties;
        this.institutionList = institutions;
        this.educationList = educations;
        if (cb) cb();
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch All specialties */
  getSpecialtyList = async () => {
    try {
      let specialties;
      if (!this.specialtyList.length) {
        const specialtysResp = await getSpecialties();
        specialties = specialtysResp?.data?.specialties;
      }
      runInAction(() => {
        if (!this.specialtyList.length) {
          this.specialtyList = specialties;
        }
        // cb();
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** Fetch institutions list */
  getInstitutionList = async (cb: () => {}) => {
    try {
      const resp = await getInstitutions();
      const {institutions} = resp?.data;
      institutions.splice(0, 0, {
        id: 0,
        name: `I don't see my institution`,
      });
      runInAction(() => {
        this.institutionList = institutions;
        cb();
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /**
   * Update registration form with new values
   * @param {object} newVal
   */
  setRegistrationForm = (newVal: $Shape<RegistrationForm>) => {
    this.registrationForm = {
      ...this.registrationForm,
      ...newVal,
    };
  };

  getCurrentAccess = (user: User) => {
    if (user.access && user.access.length && user.access?.length > 1) {
      user.access.sort((a, b) => {
        const aDate = new Date(a.AccessHistory.created_at);
        const bDate = new Date(b.AccessHistory.created_at);
        return bDate - aDate;
      });
      return user.access[0];
    }
    // No need to sort since this is the only access value.
    if (user.access && user.access.length) {
      return user.access[0];
    }
    return null;
  };

  /**
   * Set active registration step
   * @param {object} user
   */
  setUser = (user: User) => {
    this.user = user;
    this.userId = user.id;

    if (this.registrationForm.email === '') {
      this.setRegistrationForm({
        email: user.email,
      });
    }

    if (user.access && user.access.length) {
      this.setAccessUser(this.getCurrentAccess(user));
    }
  };

  /**
   * Set access user if active or inactive
   * @param {object} access
   */
  setAccessUser = (access: AccessUser) => {
    const today = Date.now();
    if (access.AccessHistory?.extended_date) {
      const extendedDate = new Date(access.AccessHistory.extended_date).setMilliseconds(0);
      if (today <= extendedDate) this.accessStatus = 'Active';
      else this.accessStatus = 'Inactive';
    } else if (access.access_period_days) {
      const createdDate = new Date(access.AccessHistory.created_at);
      const endDate = new Date(createdDate.setDate(createdDate.getDate() + access.access_period_days)).getTime();
      if (today <= endDate) {
        this.accessStatus = 'Active';
      } else {
        this.accessStatus = 'Inactive';
      }
    } else {
      let startDate = Date.now();
      let endDate = Date.now();
      if (access.start_date) startDate = new Date(access.start_date).setMilliseconds(0);
      if (access.end_date) endDate = new Date(access.end_date).getTime();
      if (today >= startDate && today <= endDate) this.accessStatus = 'Active';
      else this.accessStatus = 'Inactive';
    }
  };

  /**
   * Set active registration step
   * @param {string} step active  step
   */
  setActiveStep = (step: RegistrationType) => {
    if (step === '/access/account') {
      history.push('/access/account');
      return;
    }
    if (step === 'plan') {
      history.push('/register');
      return;
    }
    history.push(`/register/${step}`);
  };

  /**
   * Select priceId
   * @param {string} price
   */
  onSelectPrice = (price: Price) => {
    this.selectedPrice = price;
    if (this.selectedPrice?.metadata?.has_giftcard !== 'true') {
      this.selectedGiftCard = null;
      this.isSelectGiftCard = false;
    } else {
      this.isSelectGiftCard = true;
    }
  };

  onSelectProduct = (product: any) => {
    this.selectedProduct = product;
  };

  onClickGiftCard = value => {
    this.isSelectGiftCard = value;
  };

  onSelectGiftCard = giftCard => {
    this.selectedGiftCard = giftCard;
    this.onClickGiftCard(true);
  };

  /** calls the completeRegistration function for register api call
   * @params {object} formData - contains email, password, firstName and lastName
   */
  completeRegistration = async () => {
    const purchaseData = this.registrationForm;
    this.accountRegistering = true;
    try {
      const response = await completeAccountRegistration(purchaseData, this.userId);
      runInAction(async () => {
        // this.registrationForm = {};
        this.emailRegistered = true;
        this.accountRegistering = false;
        const {user, access_token, refresh_token} = response.data;
        this.user.is_registration_completed = user.is_registration_completed;
        this.user.is_subscribed = user.is_subscribed;
        localStorage.setItem('user', JSON.stringify(user));
        LocalStorageService.setToken({access_token, refresh_token});
        this.isCompleteRegistration = true;
        const res = await getAccount();
        const account = res?.data?.user || null;
        this.accountStore.setAccountAndStats(account);
        SocketClient.connect();
        if (this.accessStatus) {
          this.setActiveStep('profession');
        } else if (response && this.selectedProduct?.name !== 'Free Plan') {
          this.setActiveStep('payment');
        }

        if (this.selectedProduct?.name === 'Free Plan') {
          const plan = this.selectedPrice?.metadata?.id;
          const priceId = this.selectedPrice?.id;
          const customerId = this.user?.stripe_id || this.accountStore.account?.stripe_id;
          const email = this.user?.email || this.accountStore.account?.email;
          if (!email || !customerId || !priceId) {
            throw new Error('Invalid data');
          }
          const body: CreateSubscriptionBody = {
            email,
            paymentMethodId: null,
            customerId,
            priceId,
            plan,
            skipTrial: true,
          };
          await Api.createSubscription(body).then(
            resp => {
              const subscription = {};
              subscription.name = resp?.data?.subscription.plan.nickname;
              subscription.status = resp?.data?.subscription.status;
              this.accountStore.account.subscription = subscription;
              return resp?.data?.subscription;
            },
            err => {
              throw (
                (err.response?.data && err.response?.data?.error?.raw) || {
                  message: 'Error',
                }
              );
            },
          );
          // Create the subscription
          const uet_report_conversion = () => {
            window.uetq = window.uetq || [];
            window.uetq.push('event', 'Start Subscription', {});
          };
          uet_report_conversion();
          const redirectUrl = LocalStorageService.getRedirectUrl();
          if (redirectUrl && redirectUrl !== 'undefined') {
            history.push(redirectUrl);
          } else {
            history.push('/');
          }
          // this.setActiveStep('profession');
        }
      });
    } catch (error) {
      runInAction(() => {
        this.accountRegistering = false;
        this.registrationError = error;
      });
    }
  };

  setShowUpdateModal = val => {
    this.showUpdateModal = val;
  };

  /** calls the login function for api call
   * @params {object} formData - contains email address and password
   */
  onLogin = async (formData: any): void => {
    this.loginResponseText = null;
    this.loginEmailResponseText = null;
    try {
      const response = await login(formData);
      const {user, access_token, refresh_token} = response.data;
      runInAction(() => {
        this.loginResponseText = null;
        this.loginEmailResponseText = null;
        this.emailRegistered = true;
        this.setUser(user);
        this.setProfessionFields(this.user?.profession);
        if (this.user?.is_registration_completed) {
          localStorage.setItem('user', JSON.stringify(user));
          LocalStorageService.setToken({refresh_token, access_token});
          SocketClient.connect();
          window.location.reload();
          setAmplitudeUserId();
          // log user-session-table on login
          this.logUserSession();
          if (!this.user?.profession_specialty && this.user?.role !== 'INSTITUTION_MANAGER') {
            if (this.user.profession_id) {
              this.setShowUpdateModal(true);
              history.push('/');
            } else this.setActiveStep('profession');
          } else {
            this.registrationForm = {
              ...this.registrationForm,
              profession_id: this.user?.profession_id?.toString(),
            };

            if (
              !this.user?.is_subscribed &&
              (!this.accessStatus || this.accessStatus === 'Inactive') &&
              this.selectedProduct?.name !== 'Free Plan'
            ) {
              // redirect to update subscriptionpage
              history.push('/profile/plan-update');
            } else history.push('/');
          }
        } else {
          this.setActiveStep('profession');
        }
      });
    } catch (e) {
      runInAction(() => {
        if (_.get(e, 'response.status') === 401) {
          if (e.response.data.error === 'User not found')
            this.loginEmailResponseText = 'Account does not exists. Create account.';
          else this.loginResponseText = e.response.data.error;
        } else if (_.get(e, 'response.data.validationError')) {
          this.loginResponseText = 'Please check your login information';
        } else {
          this.loginResponseText =
            'Unexpected error has occurred. Please contact support@giblib.com to resolve this issue.';
        }
      });
    }
  };

  /** removes token and user from localstorage for logging out */
  logout = async () => {
    try {
      this.isCompleteRegistration = false;
      await logoutUser();
      SocketClient.disconnect();
      runInAction(() => {
        this.loginResponseText = null;
        this.socialConnectionStatusText = null;
        this.forgotPasswordResponseText = null;
        this.registeredEmailResponseText = null;
      });
    } catch (error) {
      this.errorStore.setError(error);
    }
  };

  /** calls the forgotPassword function for api call
   * @params {object} formData - contains email address
   */
  onForgotPassword = async (formData: any) => {
    try {
      await forgotPassword(formData);
      this.resetPasswordStatus = true;
      this.forgotPasswordResponseText = 'An email has been sent to you, please check your inbox.';
    } catch {
      this.resetPasswordStatus = false;
      this.forgotPasswordResponseText = 'Email does not match our records.';
    }
  };

  /* reset the forgotPasswordResponseText while email is changed after the error */
  resetForgotPasswordResponseText = () => {
    this.forgotPasswordResponseText = null;
  };

  /** calls the resetPassword function for api call
   * @params {object} formData - contains password and token
   * @return response status
   */
  onResetPassword = async (formData: any) => {
    try {
      const response = await resetPassword(formData);
      return {status: response?.data?.status};
    } catch (error) {
      return {status: false, error};
    }
  };

  sendHubpostToPlanName = async () => {
    await completePlan(this.userId, this.selectedPrice.nickname);
  };

  /** calls the resetPassword function for api call
   * @params {object} formData - contains password and token
   */
  onRegisterEmail = async (email: string, redirectOnSuccess = 'plan') => {
    this.emailRegistered = false;
    try {
      this.setRegistrationForm({
        email,
      });
      const registerResponse = await registerEmail(this.registrationForm);

      if (registerResponse.status) {
        trackUserEmail(email);
        setAmplitudeUserId();
        runInAction(async () => {
          this.setUser(registerResponse.data.user);
          if (registerResponse.status === 200) {
            this.registeredEmailResponseText = 'An email has been sent to you. Please verify';
          }
          if (registerResponse.status === 202) {
            this.emailRegistered = true;
            if (redirectOnSuccess) {
              this.setActiveStep(redirectOnSuccess);
            }
          }
        });
      }
    } catch (error) {
      const response = {...error};
      runInAction(() => {
        this.emailRegistered = false;
        if (response?.response?.status === 403) {
          this.registeredEmailResponseText = 'Email not verified';
        }
        if (response?.response?.status === 400) {
          this.registeredEmailResponseText = 'Email already registered. Please login';
        }
      });
    }
  };

  onResetResponseText = () => {
    this.verifiedEmailResponseText = '';
    this.loginResponseText = null;
    this.loginEmailResponseText = null;
  };

  /** verify the access email
   * @params email - email
   */
  onVerfiyAccessEmail = async (email: string) => {
    this.emailRegistered = false;
    this.gotoLogin = false;
    this.setRegistrationForm({
      email,
    });

    try {
      const response = await verifyAccessEmail(email);
      if (response.status) {
        this.verifiedStatus = true;

        runInAction(() => {
          if (response.accessHistory) {
            this.setUser(response.user);
          }
          if (this.accessStatus === 'Active') {
            if (!response.user.is_registration_completed) {
              this.emailRegistered = true;
              this.setActiveStep('/access/account');
            } else {
              this.verifiedEmailResponseText = 'Active account already exists. Please login.';
              history.push('/login');
            }
          } else {
            this.verifiedStatus = false;
          }
        });
      }
    } catch (error) {
      const response = {...error.response};
      runInAction(() => {
        this.emailRegistered = false;
        if (response.status === 400) {
          this.verifiedStatus = false;
        }
      });
    }
  };

  /** create the access email
   * @params formData - contains accessCode and email
   */
  onCreateAccessEmail = async (formData: any) => {
    this.emailRegistered = false;
    this.verifiedEmailResponseText = null;
    this.createdStatus = false;
    this.setRegistrationForm({
      email: formData.email,
    });
    this.accessCode = formData.accessCode;
    this.loading = true;

    try {
      const response = await createAccessEmail(formData);
      if (response.status) {
        this.setUser({
          ...response.user,
          access: [{...response.accessCoupon, AccessHistory: response.accessHistory}],
        });
        if (this.user.is_registration_completed) this.isOriginUser = true;
        else this.isOriginUser = false;

        runInAction(() => {
          this.loading = false;
          if (this.accessStatus === 'Active') {
            this.createdStatus = true;
            this.emailRegistered = true;
            if (response.user.is_registration_completed) {
              const {refresh_token, access_token} = response;
              LocalStorageService.setToken({refresh_token, access_token});
              SocketClient.connect();
              history.push('/login');
            } else {
              this.setActiveStep('/access/account');
            }
          } else {
            this.createdStatus = false;
            this.verifiedEmailResponseText = 'Access code not valid.';
          }
        });
      }
    } catch (error) {
      const response = {...error.response};
      runInAction(() => {
        this.loading = false;
        this.emailRegistered = false;
        if (response.status === 400) {
          this.createdStatus = false;
          this.verifiedEmailResponseText = 'Access code not valid.';
        }
      });
    }
  };

  setProfessionFields = (field: Profession) => {
    this.professionNext = field?.metadata?.tags;
    this.profession_specialtyType = field?.specialty_type;
  };

  onSetActiveStep = async (purchaseData: RegistrationForm) => {
    if (!this.user.id) this.setUser(this.accountStore.account);
    this.setRegistrationForm({
      email: this.user.email,
    });
    this.emailRegistered = true;
    if (this.user.is_registration_completed) {
      try {
        const res = await setAccount(purchaseData);
        const {status} = res.data;

        if (status) {
          await this.accountStore.getAccount();
          if (this.accessStatus === 'Active' && this.getCurrentAccess(this.user)?.content_based) {
            history.push('/purchased');
          } else {
            history.push('/');
          }
        }
      } catch (error) {
        this.errorStore.setError(error);
      }
    } else if (this.user.is_subscribed || this.accessStatus === 'Active') {
      if (!this.user.profession_specialty) {
        this.setActiveStep('profession');
      } else {
        if (this.user.profession_id) {
          this.setRegistrationForm({
            profession_id: this.user.profession_id,
          });
        }
        if (this.user.is_subscribed) {
          this.setActiveStep('account');
        } else {
          this.setActiveStep('/access/account');
        }
      }
    } else if (!(this.user.is_subscribed || this.accessStatus === 'Active')) {
      this.setActiveStep('plan');
    }
  };

  /** sets the profession value
   * @params {object} formData - contains profession
   */
  onSetProfession = ({
    profession_id,
    otherProfession,
  }: $Shape<RegistrationForm> & {
    otherProfession?: string,
  }) => {
    this.loading = true;
    this.setRegistrationForm({
      profession_id,
    });

    const partialAttrs = {
      profession_id,
      extra_data: {
        other_profession: otherProfession || null,
      },
    };
    partialUpdate(this.user.id, partialAttrs).then(res => {
      if (res) {
        const {subscription} = this.accountStore.account;
        this.accountStore.account = res;
        if (subscription) {
          this.accountStore.account.subscription = subscription;
        }
        this.loading = false;
        history.push('/');
      }
    });

    // if (response && this.profession_specialtyType && this.profession_specialtyType !== 'other') {
    //   this.setActiveStep('specialty');
    // } else {
    //   const purchaseData = {
    //     profession_id,
    //     extra_data: {
    //       other_profession: otherProfession,
    //     },
    //     profession_specialty: true,
    //   };
    //   this.user.profession_specialty = true;
    //   this.onSetActiveStep(purchaseData);
    // }
  };

  onCompleteSpecialties = async specialties => {
    await completeSpecialties(specialties, this.userId);
  };

  /** sets the specialty and subspecialty value
   * @params {object} formData - contains specialty and subspecialty
   */
  onSetSpecialty = specialty => {
    const specialty_ids = [];
    let otherspecialty = null;
    let othersubspecialty = null;
    specialty.forEach(val => {
      specialty_ids.push(val.specialty_id);
      val.subspecialty_id.map(val2 => specialty_ids.push(val2));
      if (val.otherSpecialty) otherspecialty = val.otherSpecialty;
      if (val.otherSubspecialty) othersubspecialty = val.otherSubspecialty;
    });

    this.setRegistrationForm({
      specialty_ids,
      extraData: {
        ...this.registrationForm.extraData,
        other_specialty: otherspecialty,
        other_subspecialty: othersubspecialty,
      },
      profession_specialty: true,
    });
    this.user.profession_specialty = true;

    const profession_id = this.registrationForm?.profession_id;
    const other_profession = this.registrationForm.extraData?.other_profession || null;

    const partialAttrs = {
      profession_id,
      specialty_ids,
      extra_data: {
        other_specialty: otherspecialty,
        other_profession,
        other_subspecialty: othersubspecialty,
      },
    };
    partialUpdate(this.user.id, partialAttrs);

    // setSpecialty complete registration
    const purchaseData = {
      profession_id,
      specialty_ids,
      extra_data: {
        other_specialty: otherspecialty,
        other_profession,
        other_subspecialty: othersubspecialty,
      },
      profession_specialty: true,
    };
    this.onSetActiveStep(purchaseData);
    this.onCompleteSpecialties(specialty_ids);
  };

  /** set values of firstName, lastName, password
   * @params {object} formData - contains profession
   */
  onSetAccountDetails = ({
    email,
    firstName,
    lastName,
    licenseNumber,
    hospital,
    specialty_id,
    subSpecialty,
    password,
    profession_id,
    is_hcp,
    extraData,
  }: RegistrationForm) => {
    this.setRegistrationForm({
      email,
      firstName,
      lastName,
      licenseNumber,
      hospital,
      specialty_id,
      subSpecialty,
      password,
      profession_id,
      is_hcp,
      extraData,
    });
    this.completeRegistration();
  };

  /** set values of email, socialEmail, socialID, accessToken, socialLoginType and call socialConnect API
   * @params {object} formData - contains profession
   */
  onSocialConnect = (formData: any, socialLoginType: string) => {
    this.socialConnectionStatusText = null;
    const {email} = JSON.parse(localStorage.getItem('user') || '{}');
    const socialData = {
      email,
      socialEmail: socialLoginType === 'facebook' ? formData.email : formData?.profileObj?.email,
      socialID: socialLoginType === 'facebook' ? formData.id : formData.googleId,
      accessToken: formData.accessToken,
      socialLoginType,
    };
    socialConnect(socialData)
      .then(response => {
        localStorage.setItem('user', JSON.stringify(response.data.user));
        this.socialConnectionStatus = true;
        this.socialConnectionStatusText = 'Successfully connected';
        this.checkSocialConnectionStatus();
      })
      .catch(() => {
        this.socialConnectionStatus = false;
        this.socialConnectionStatusText = 'Connection failed';
      });
  };

  /** set values of socialID, accessToken, socialLoginType and call socialLogin API and sets the user and token in the local storage if success
   * @params {object} formData - contains profession
   */
  onSocialLogin = (formData: any, socialLoginType: string) => {
    const socialData = {
      socialID: socialLoginType === 'facebook' ? formData.id : formData.googleId,
      accessToken: formData.accessToken,
      socialLoginType,
      socialEmail: socialLoginType === 'facebook' ? formData?.email : formData?.profileObj?.email,
    };

    socialLogin(socialData)
      .then(response => {
        this.loginResponseText = null;

        if (response.status === 200) {
          const {user, refresh_token, access_token} = response.data;
          trackUserEmail(user.email);
          this.setUser(user);
          this.setProfessionFields(this.user?.profession);
          this.emailRegistered = true;
          if (this.user.is_registration_completed) {
            localStorage.setItem('user', JSON.stringify(user));
            LocalStorageService.setToken({refresh_token, access_token});
            SocketClient.connect();
            // log user-session-table on login
            this.logUserSession();
            if (!this.user.profession_specialty) this.setActiveStep('specialty');
            else {
              this.registrationForm.profession_id = this.user.profession_id.toString();

              if (!this.user.is_subscribed && (!this.accessStatus || this.accessStatus === 'Inactive')) {
                this.setActiveStep('plan');
              } else {
                this.accountStore.getAccount();
                history.go(-1);
                // safari/firefox doesn't reload after going back
                window.location.reload();
              }
            }
          } else {
            this.setActiveStep('specialty');
          }
        } else if (response.status === 202) {
          this.setActiveStep('email');
        }
      })
      .catch(error => error);
  };

  /** set values of isGoogleConnected, isFacebookConnected after checking the user in the local storage
   */
  checkSocialConnectionStatus = () => {
    const {google, facebook} = JSON.parse(localStorage.getItem('user') || '{}');
    if (google) {
      this.isGoogleConnected = true;
    } else {
      this.isGoogleConnected = false;
    }
    if (facebook) {
      this.isFacebookConnected = true;
    } else {
      this.isFacebookConnected = false;
    }
  };

  setIsContentRequestSuccess = (value: boolean) => {
    this.isContentRequestSuccess = value;
  };

  requestContent = async formData => {
    this.isContentRequestSuccess = false;
    const data = {
      specialty: formData.specialty,
      otherSpecialty: formData.otherspecialty,
      content: formData.contentrequest,
      userEmail: formData.useremail,
    };
    try {
      const resp = await requestContent(data);
      const {status} = resp.data;
      if (status) this.isContentRequestSuccess = true;
    } catch (error) {
      this.errorStore.setError(error);
    }
  };
}

export default AuthStore;
