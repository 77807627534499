/* eslint-disable camelcase */
import axios from 'axios';
import LocalStorageService from '../utils/LocalStorageService';
import {trackUserEmail} from '../hubspot';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});
/** calls the register api and alerts the user on success
 * @params {object} formData - contains email, password, firstName and lastName
 * @params {number} id - id of the user
 */
export const completeAccountRegistration = (formData, userId) => {
  const userBody = {
    email: formData.email,
    password: formData.password,
    first_name: formData.firstName,
    last_name: formData.lastName,
    license_number: formData.licenseNumber,
    primary_hospital: formData.hospital,
    specialty_id: formData.specialty_id,
    subspecialty: formData.subSpecialty,
    profession_id: formData.profession_id,
    specialty_ids: formData.specialty_ids,
    extra_data: formData.extraData,
    profession_specialty: true,
    is_hcp: formData.is_hcp,
  };
  return instance.put(`auth/complete-registration/${userId}`, userBody);
};

/** calls the login api and sets the user and token in the local storage if success
 * @params {object} formData - contains email address and password
 * @return status as true if success or false on failure
 */
export const login = formData => {
  const {email, password} = formData;
  return instance
    .post('auth/login', {
      email,
      password,
    })
    .then(res => {
      const {access_token, refresh_token} = res?.data || {};
      if (access_token && refresh_token) {
        LocalStorageService.setToken({access_token, refresh_token});
      }
      return res;
    });
};
/** calls the logot api and remove the user and token in the local storage if success
 * @params {object} formData - contains email address and password
 * @return status as true if success or false on failure
 */
export const logout = () => {
  const refreshToken = LocalStorageService.getRefreshToken();
  return instance
    .delete('auth/logout', {
      data: {refresh_token: refreshToken},
    })
    .then(res => {
      LocalStorageService.clearToken();
      LocalStorageService.clearRedirectUrl();
      return res;
    });
};

/** calls the forgot password api
 * @params {object} formData - contains email address
 * @return status as true if success or false on failure
 */
export const forgotPassword = formData => {
  const {email} = formData;
  return instance.post('auth/forgot-password', {
    email,
  });
};

/** calls the reset password api
 * @params {object} formData - contains password and token
 * @return status as true if success or false on failure
 */
export const resetPassword = formData => {
  const {password, token} = formData;
  return instance.post('auth/reset-password', {
    password,
    token,
  });
};

/** calls the register-email api
 * @params {string} email - email
 * @return status as true if success or false on failure
 */
export const registerEmail = ({email, campaignCode}) => {
  return instance.post(`${process.env.REACT_APP_API_ENDPOINT}/auth/register-email`, {email, campaignCode});
};

/** calls the verify api
 * @params {string} token - token
 * @return res.data if success or err.response.data on failure
 */
export const verifyEmail = token => {
  return instance.put(`/auth/verify/?token=${token}`).then(res => res?.data);
};

/** calls the verify api
 * @params {string} email - email
 * @return res.data if success or err.response.data on failure
 */
export const verifyAccessEmail = email => {
  return instance.post('/auth/verify-institutional-user', {email}).then(res => res?.data);
};

/** calls the create access user api
 * @params {string} formData - contains accessCode and email
 * @return res.data if success or err.response.data on failure
 */
export const createAccessEmail = formData => {
  const {accessCode, email} = formData;
  trackUserEmail(email);
  return instance.post('/auth/create-institutional-user', {accessCode, email}).then(res => res?.data);
};

/** calls the reset password api
 * @params {object} formData - contains email, socialEmail, socialID, accessToken and socialLoginType
 * @return status as true if success or false on failure
 */
export const socialConnect = formData => {
  return instance.put('auth/social-connect', formData);
};

/** calls the socialLogin api
 * @params {object} formData - contains socialID, accessToken and socialLoginType
 * @return status as true if success or false on failure
 */
export const socialLogin = formData => {
  return instance.post('auth/social-login', formData);
};

export const completeSpecialties = (specialty_ids, userId) =>
  instance.put(`auth/complete-specialty/${userId}`, specialty_ids);

export const completePlan = (userId, plan_name) => instance.put(`auth/complete-planname/${userId}`, {plan_name});
