import axios from './axios';

/**
 * Calls the cme GET list api and set inprogress cmes
 */

export const getCmes = (type, sort, sortBy, page, count, start, end) =>
  axios.get('/cme/list', {
    params: {
      type,
      sort,
      sortBy,
      page,
      limit: count,
      start,
      end,
    },
  });

export const startCME = ocmeVid => axios.post(`/cme/start`, {ocme_vid: ocmeVid});

export const submitPreTestAnswers = (preTestAnswers, ocmeVid) =>
  axios.post('/cme/submit-pretest', {preTestAnswers, ocme_vid: ocmeVid});

export const downloadCME = id =>
  axios
    .get(`/cme/${id}/download`, {
      responseType: 'blob',
    })
    .then(response => {
      const contentDisposition = response?.headers['content-disposition'];
      const match = contentDisposition?.match(/filename\s*=\s*"(.+)"/i);
      const filename = match[1];
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
export const downloadCMEZip = cmeIds =>
  axios
    .get('/cme/download', {
      params: {
        cmeIds,
      },
      responseType: 'blob',
    })
    .then(async response => {
      const contentDisposition = response?.headers['content-disposition'];
      const match = contentDisposition?.match(/filename\s*=\s*"(.+)"/i);
      const filename = match[1];
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
export const deleteCme = id => axios.delete(`/cme/${id}`);

export const getGoal = () => axios.get(`/cme/cme-goal`);

export const setGoal = (creditGoal, startDate, endDate) =>
  axios.post(`/cme/cme-goal`, {creditGoal, startDate, endDate});

export const updateGoal = (creditGoal, startDate, endDate, id) =>
  axios.put(`/cme/cme-goal/${id}`, {creditGoal, startDate, endDate});

/**
 * calls the POST api to set answers
 */
export const setCmeAnswer = answer => axios.post(`/cme`, answer);

/**
 * calls the POST api to retake CME
 */
export const retakeCME = (cmeId, ocmeVid) => axios.post(`/cme/retake-cme`, {cme_id: cmeId, ocme_vid: ocmeVid});

export const selectAllCompleted = (start, end) =>
  axios.get('/cme/completed', {
    params: {
      start,
      end,
    },
  });

export const clearGoal = () => axios.put(`/cme/clear-goal`);

export const saveOrSubmitPostTestSurvey = answer => axios.post(`/cme/submit-posttest-survey`, answer);
