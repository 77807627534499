/* istanbul ignore next */
import React from 'react';
import {FinishAssessment, StartAssessment} from './style';

export const StartAssessmentButton = ({watched, ...rest}: any) => {
  return (
    <StartAssessment {...rest} watched={watched}>
      <span>
        Start Assessment <i className="fas fa-chevron-right" />{' '}
      </span>
    </StartAssessment>
  );
};

export const FinishAssessmentButton = ({watched, ...rest}: any) => {
  return (
    <FinishAssessment {...rest}>
      <span>
        Finish Assessment <i className="fas fa-chevron-right" />{' '}
      </span>
    </FinishAssessment>
  );
};

export const StartPostTestAssessmentButton = ({watched, postTestPassed, ...rest}: any) => {
  return (
    <StartAssessment {...rest} style={{background: '#008aff'}}>
      {postTestPassed ? (
        <span style={{color: '#ffffff'}}>Continue Post Test</span>
      ) : (
        <span style={{color: '#ffffff'}}>Start Post Test</span>
      )}
    </StartAssessment>
  );
};

export const StartPreTestAssessmentButton = ({watched, ...rest}: any) => {
  return (
    <StartAssessment {...rest} style={{background: '#008aff'}}>
      <span style={{color: '#ffffff'}}>Start CME</span>
    </StartAssessment>
  );
};

export const StartPostTestSurveyButton = ({watched, ...rest}: any) => {
  return (
    <StartAssessment {...rest} style={{background: '#008aff'}}>
      <span style={{color: '#ffffff'}}>Start Final Survey</span>
    </StartAssessment>
  );
};

export default StartAssessmentButton;
