// @flow
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Container, ProgressBar, OverlayTrigger} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import Moment from 'react-moment';
import {amplitude} from '../../../utils/Amplitude';
import LocalStorageService from '../../../utils/LocalStorageService';
import {StartAssessmentButton, FinishAssessmentButton} from '../../../components/AssessmentButton';
import {DeleteModal} from './DeleteModal';
import AlertComponent from '../../../components/AlertComponent';
import PlaylistPopover from '../../../components/PlaylistPopover';
import UpgradeModal from '../../../components/UpgradeModal';
import {useStore} from '../../../store';
import Thumbnail from '../elements/video-thumbnail.png';
import {
  ThumbnailDiv,
  ThumbnailImg,
  ButtonGroup,
  VideoTitle,
  Organization,
  Details,
  VideoCardHeader,
  VideoCard,
  DetailDiv,
} from '../elements/cme';
import {secondsToTime} from '../../../utils/helper';

type CmeTest = {
  id: number,
  pre_test_response?: object | null,
  post_test_response?: object | null,
  survey_response?: object | null,
  is_post_test_passed?: Boolean,
};

type Props = {
  item: {
    id: number,
    cme: Boolean,
    organizationCME: {
      id: number,
      credit_count: number,
      release_date: Date,
      cme_expire_at: Date,
      withdraw_at: Date,
      free_cme: Boolean,
      cme_questions: {
        questions: Array,
      },
      survey_questions: {
        questions: Array,
      },
      video: {
        id: number,
        title: string,
        thumbnail: string,
        organization: {
          name: string,
        },
        duration: number,
        watchTime: number,
        bookmark: {
          id: number,
        },
      },
      CmeTests: CmeTest[],
    },
    organization: {
      name: string,
    },
  },
};

const CmeVideo = observer(({item}: Props) => {
  const location = useHistory();
  const [watchProgress, setWatchProgress] = useState(0);
  const [isExpired, setExpired] = useState(false);
  const {videoStore, accountStore, cmeStore, alertStore} = useStore();
  const {account} = accountStore;
  const {message, status, resetAlert} = alertStore;
  const {
    showQuestions,
    deleteInProgressCme,
    setVideoId,
    getQuestions,
    setvideoTitle,
    setshowQuestionBlock,
    setExistingPostTestAnswers,
    setPostTestAttempts,
    setPostTestPassed,
    setPreTestSubmitted,
  } = cmeStore;
  const {
    playlist,
    getAllPlaylists,
    addToPlaylist,
    updatePlaylist,
    toggleVideoBookmark,
    setFreeCme,
    setPostTestSurveyQuestions,
    setPreTestQuestions,
    setSurveySubmitted,
  } = videoStore;

  /* Delete cme modal state */
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteModalShow = () => setShowDeleteModal(true);
  const handleDeleteModalClose = () => setShowDeleteModal(false);

  const getRemainingDays = (date: Date) =>
    date ? Math.floor((new Date(date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) : 0;

  useEffect(() => {
    const wTime = item?.organizationCME?.video?.watchTime || 0;
    const duration = item?.organizationCME?.video?.duration || 0;
    const progress = Math.round((wTime / duration) * 100);
    setWatchProgress(progress);

    const exp = item?.organizationCME?.cme_expire_at || null;
    if (exp) {
      const days = getRemainingDays(exp);
      if (days < 0) setExpired(true);
    }
  }, [item]);

  const [isShowBookmarkUpgradeModal, setShowBookmarkUpgradeModal] = useState(false);
  const [isShowPlaylistUpgradeModal, setShowPlaylistUpgradeModal] = useState(false);
  const [isShowFollowUpgradeModal, setShowFollowUpgradeModal] = useState(false);
  const [userState, setUserState] = useState(false);
  const loggedInUser = LocalStorageService.getUser();

  useEffect(() => {
    if (!account?.subscription?.name?.includes('Basic')) {
      setUserState(true);
      getAllPlaylists();
    }
  }, [account, getAllPlaylists]);

  const createAndAddToPlaylist = data => {
    addToPlaylist(item?.organizationCME?.video?.id, data.listname, true);
  };

  const updateExistingPlaylist = data => {
    updatePlaylist(item?.organizationCME?.video?.id, data, true);
  };

  const [bookmarkIcon, setBookmarkIcon] = useState(false);

  useEffect(() => {
    if (item?.organizationCME?.video?.bookmark?.length) setBookmarkIcon(true);
  }, [item]);

  const toggleBookmark = () => {
    if (loggedInUser) {
      if (!account?.subscription?.name?.includes('Basic')) {
        toggleVideoBookmark(bookmarkIcon, item?.organizationCME?.video?.id).then(() => {
          setBookmarkIcon(!bookmarkIcon);
        });
        amplitude.getInstance().logEvent('bookmarked');
      } else setShowBookmarkUpgradeModal(true);
    } else location.push('/login');
  };

  const handleSubmitForm = () => {
    deleteInProgressCme(item.id);
    setShowDeleteModal(false);
  };

  const handleDeleteModal = () => {
    handleDeleteModalShow();
  };

  const openVideo = video => {
    location.push(`/video/${video.id}`);
  };

  const onfinishAssessmentbtnClick = () => {
    if (item.organizationCME.free_cme) {
      setFreeCme(true);
      const cmeTests = item.organizationCME.CmeTests || [];
      if (cmeTests.length !== 0) {
        if (cmeTests[0].pre_test_response && cmeTests[0].pre_test_response.length !== 0) {
          const pretestQuestions = item?.organizationCME?.cme_questions.questions || [];
          setPreTestQuestions(pretestQuestions);
          setPreTestSubmitted(true);
          setExistingPostTestAnswers(cmeTests[0]);
          setPostTestAttempts(cmeTests[0].attempts);
        }
        if (cmeTests[0].is_post_test_passed) {
          const postTestCmeSurveyQuestions = item?.organizationCME?.survey_questions.questions || [];
          setPostTestSurveyQuestions(postTestCmeSurveyQuestions);
          setPostTestPassed(true);
        }
        if (cmeTests[0].survey_response && cmeTests[0].survey_response.length !== 0) {
          setSurveySubmitted(true);
          // const cme = item?.organizationCME?.CMEs[0];
          // setFreeCmeCertificateData(cme.id);
        }
      } else {
        location.push(`/video/${item?.organizationCME?.video?.id}`);
      }
    }
    showQuestions(true);
    setVideoId(item?.organizationCME?.video?.id);
    setvideoTitle(item?.organizationCME?.video?.title);
    getQuestions();
    setshowQuestionBlock(true);
  };

  return (
    <>
      <UpgradeModal
        access="bookmark"
        isShow={isShowBookmarkUpgradeModal}
        onHideModal={() => setShowBookmarkUpgradeModal(false)}
      />
      <UpgradeModal
        access="bookmark"
        isShow={isShowFollowUpgradeModal}
        onHideModal={() => setShowFollowUpgradeModal(false)}
      />
      {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
      <VideoCard>
        <Container>
          <div className="card-border-bottom d-md-flex card-padding" id={item.id}>
            <div className="detail-container w-100 d-flex">
              <ThumbnailDiv>
                <ThumbnailImg
                  src={item?.organizationCME?.video?.thumbnail || Thumbnail}
                  alt={item?.organizationCME?.video?.title}
                  onClick={() => openVideo(item?.organizationCME?.video)}
                />
                <ProgressBar now={watchProgress} />
              </ThumbnailDiv>
              <DetailDiv className="w-100">
                <VideoCardHeader className="d-flex">
                  <div className="d-flex">
                    <div className={`progress-badge ${isExpired ? 'expired-badge' : 'inProgress-badge'}`}>
                      {isExpired ? 'Expired' : 'In Progress'}
                    </div>
                    {item?.organizationCME ? (
                      <span className="credit">
                        {item?.organizationCME?.credit_count} Credit
                        {item?.organizationCME?.credit_count > 1 ? 's' : ''}
                      </span>
                    ) : null}
                  </div>
                </VideoCardHeader>
                <VideoTitle onClick={() => openVideo(item?.organizationCME?.video)}>
                  <h5>{item?.organizationCME?.video?.title}</h5>
                </VideoTitle>
                <Organization>
                  {item?.organizationCME?.video?.organization?.name}
                  {item?.organizationCME?.video?.organization?.name &&
                  item?.organizationCME?.video?.specialties.length > 0
                    ? ' | '
                    : ''}
                  {item?.organizationCME?.video?.specialties.length > 0
                    ? `${item?.organizationCME?.video?.specialties
                        .slice(0, 2)
                        ?.map((s, i) => (i !== 0 ? ` ${s.display_name}, ...` : s.display_name))}`
                    : ''}
                </Organization>
                <Details>
                  {secondsToTime(item?.organizationCME?.video?.duration || 0)}
                  &nbsp;|&nbsp;
                  <Moment format="MM/YYYY">{item?.organizationCME?.release_date}</Moment>
                  &nbsp;|&nbsp;
                  {isExpired ? (
                    <span className="expired">
                      Expired <Moment format="MM/DD/YYYY">{item?.organizationCME?.cme_expire_at}</Moment>
                    </span>
                  ) : (
                    <span>
                      Expires <Moment format="MM/DD/YYYY">{item?.organizationCME?.cme_expire_at}</Moment>
                    </span>
                  )}
                </Details>
              </DetailDiv>
            </div>
            <ButtonGroup className="button-alignment">
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={props => (
                  <PlaylistPopover
                    props={props}
                    videoPlaylist={item?.organizationCME?.video?.playlist}
                    allPlaylist={playlist}
                    onExisting={updateExistingPlaylist}
                    onNew={createAndAddToPlaylist}
                    userState={userState}
                    showUpgradeModal={isShowPlaylistUpgradeModal}
                    onHideUpgradeModal={() => setShowPlaylistUpgradeModal(false)}
                  />
                )}
                rootClose
              >
                <Button
                  variant="basic"
                  className="a_playlist action-icon-button mr-3"
                  data-tip="Playlist"
                  data-for="videoActions"
                  onClick={() => setShowPlaylistUpgradeModal(true)}
                >
                  <i className="fak fa-list-ul" />
                </Button>
              </OverlayTrigger>
              <Button
                variant="basic"
                onClick={() => toggleBookmark()}
                className="a_bookmark action-icon-button mr-3"
                data-tip="Bookmark"
                data-for="videoActions"
              >
                {bookmarkIcon ? (
                  <span key="video_bookmark">
                    <i className="fas fa-bookmark" />
                  </span>
                ) : (
                  <span key="video_bookmark_active">
                    <i className="far fa-bookmark" />
                  </span>
                )}
              </Button>
              <Button variant="basic" className="action-icon-button a_remove" onClick={handleDeleteModal}>
                <i className="fa fa-trash" />
              </Button>
              {isExpired ? (
                <StartAssessmentButton watched={false} />
              ) : (
                <FinishAssessmentButton onClick={onfinishAssessmentbtnClick} watched />
              )}
            </ButtonGroup>
          </div>
        </Container>
      </VideoCard>
      {/* Update Modal to delete cme */}
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleDeleteModalClose={handleDeleteModalClose}
        handleSubmitForm={handleSubmitForm}
        videoTitle={item?.organizationCME?.video?.title}
        tabName="In-Progress"
      />
    </>
  );
});

export default CmeVideo;
