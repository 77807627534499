import React, {useState, useEffect} from 'react';
import {Button, Form, ProgressBar} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useStore} from '../../../../store';
import {
  QuestionDiv,
  Disclaimer,
  PostTestWrapper,
  PostTestQuestionHeadingWrapper,
  QuestionDescription,
  PostTestAnswerStatusMessage,
} from './style';
import {amplitude} from '../../../../utils/Amplitude';

const CME = observer(({videoTitle, expanded}) => {
  const {cmeStore, accountStore, videoStore} = useStore();
  const {
    currentQuestion,
    getNextQuestion,
    setAnswer,
    totalQuestion,
    getQuestionById,
    saveAnswer,
    setCurrentAnswer,
    currentAnswer,
    savePartialAnswer,
    postTestSubmission,
    savePostTestPartialAnswer,
    setshowComplete,
    setshowQuestionBlock,
    showQuestionBlock,
    questionchanged,
    postTestPassed,
    setPostTestPassed,
    savePostTestSurveyPartialAnswer,
    existingPostTestAnswers,
    postTestAttempts,
    setPostTestAttempts,
    download,
    showClaimCredit,
  } = cmeStore;
  const {updateAccountStat} = accountStore;
  const {
    freeCme,
    preTestQuestions,
    postTestSurveyQuestions,
    setPostTestSurveyQuestions,
    surveySubmitted,
    setSurveySubmitted,
    freeCmeCertificateData,
    setFreeCmeCertificateData,
  } = videoStore;

  const {register, handleSubmit} = useForm();
  const [checkedState, setCheckedState] = useState([]);
  const [choiceBlock, setChoiceBlock] = useState([]);
  const [calculateCheckedBoxes, setCalculateCheckedBoxes] = useState(false);
  const [drawChoices, setDrawChoices] = useState(false);
  // eslint-disable-next-line
  const [checked, setChecked] = useState([]);
  const [enableButton, setenableButton] = useState(false);
  const [inputAns, setInputAns] = useState();
  const [inputRemain, setInputRemain] = useState();
  const [selectedPostTestOptions, setSelectedPostTestOptions] = useState([]);
  const [postTestAnswers, setPostTestAnswers] = useState([]);
  const [postTestNoAnswerMessage, setPostTestNoAnswerMessage] = useState('');
  const [enablePostTestSaveAndQuit, setEnablePostTestSaveAndQuit] = useState(false);
  const [highlightedIncorrectAnswers, setHighlightedIncorrectAnswers] = useState([]);
  const [highlightedCorrectAnswers, setHighlightedCorrectAnswers] = useState([]);
  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [surveySelectedOptions, setSurveySelectedOptions] = useState([]);
  const [postTestShowCorrectAnswerMessage, setPostTestShowCorrectAnswerMessage] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);

  const changeInput = value => {
    setInputAns(value);
    setCurrentAnswer(value);
    if (value.length < currentQuestion.widget.min_length) {
      setInputRemain(currentQuestion.widget.min_length - value.length);
      setenableButton(false);
    } else {
      setInputRemain(0);
      setenableButton(true);
    }
    if (inputRemain <= 1) {
      setenableButton(true);
    }
  };

  const total = (currentQuestion.id / totalQuestion) * 100;
  // eslint-disable-next-line
  const handleOnChange = (e, index) => {
    let updatedCheckedState;
    if (e.target.checked) {
      updatedCheckedState = checkedState?.length
        ? [...checkedState, {checkedChoice: currentQuestion.widget?.choices[index], index}]
        : [{checkedChoice: currentQuestion.widget?.choices[index], index}];
    } else {
      updatedCheckedState = checkedState?.filter(item => item.index != index);
    }
    setCheckedState(updatedCheckedState);
    setenableButton(true);
    setCurrentAnswer(updatedCheckedState);
    setCalculateCheckedBoxes(true);
  };

  useEffect(() => {
    getNextQuestion();
  }, [getNextQuestion]);

  useEffect(() => {
    const unansweredQuestions = preTestQuestions.filter(question => {
      return !postTestAnswers.find(answer => answer.id === question.id && answer.answer);
    });

    setEnablePostTestSaveAndQuit(unansweredQuestions.length > 0);
  }, [preTestQuestions, postTestAnswers]);

  const getCorrectAnswers = () => {
    const correctAnswers = preTestQuestions.map(question => ({
      id: question.id,
      correctAnswer: question.widget.answer,
    }));
    return correctAnswers;
  };

  const handlePostTestCompleteSubmission = () => {
    let isPostTestSubmitting = false;
    if (postTestAnswers && !isPostTestSubmitting) {
      isPostTestSubmitting = true;

      const filteredAnswers = postTestAnswers.filter(
        answer => answer && Object.values(answer).every(value => typeof value !== 'undefined'),
      );

      postTestSubmission(filteredAnswers, result => {
        if (Array.isArray(result) && result.length > 0) {
          setPostTestPassed(true);
          // setShowClaimCredit(true);
          setshowQuestionBlock(true);

          setPostTestAttempts(result);
          setPostTestSurveyQuestions(result);
        } else {
          setPostTestPassed(false);
          setPostTestAttempts(result);
        }
        isPostTestSubmitting = false;
      });
    }
  };

  const downloadFreeCmeCertificate = () => {
    if (surveySubmitted && !downloadLoader) {
      setDownloadLoader(true);
      download([freeCmeCertificateData]).then(() => {
        setDownloadLoader(false);
      });
    }
  };

  const handleSubmitForm = data => {
    if (freeCme) {
      if (postTestPassed && showQuestionBlock) {
        setCheckedState([]);
        setenableButton(false);

        const unansweredQuestions = postTestSurveyQuestions.filter(question => {
          if (question.widget.type === 'slider_group') {
            return question.widget.questions.some(subQuestion => {
              // Find the slider_group parent question
              const parentAnswer = surveyAnswers.find(answer => answer.id === question.id);
              if (!parentAnswer || !Array.isArray(parentAnswer.answers)) {
                return true;
              }
              return !parentAnswer.answers.find(
                subAnswer =>
                  subAnswer.id === subQuestion.id && subAnswer.answer !== undefined && subAnswer.answer !== null,
              );
            });
          }

          if (question.widget.type === 'choice' && question.widget.multiple) {
            return !surveyAnswers.find(
              answer => answer.id === question.id && Array.isArray(answer.answer) && answer.answer.length > 0,
            );
          }

          return !surveyAnswers.find(
            answer =>
              answer.id === question.id &&
              answer.answer !== undefined &&
              answer.answer !== null &&
              answer.answer !== '',
          );
        });

        if (unansweredQuestions.length > 0) {
          // Prevent submission if there are unanswered questions
          setPostTestNoAnswerMessage('Please answer all questions before submitting.'); // Using setter for survey as post test
          return;
        }
        setPostTestNoAnswerMessage('');

        if (surveyAnswers) {
          const filteredAnswers = surveyAnswers.filter(
            answer => answer && Object.values(answer).every(value => typeof value !== 'undefined'),
          );
          savePostTestSurveyPartialAnswer(filteredAnswers, result => {
            if (result) {
              setFreeCmeCertificateData(result);
            }
          });
        }
        setSurveySubmitted(true);
        setshowQuestionBlock(false);
      } else {
        setCheckedState([]);
        setenableButton(false);

        const unansweredQuestions = preTestQuestions.filter(question => {
          return !postTestAnswers.find(answer => answer.id === question.id && answer.answer);
        });

        if (unansweredQuestions.length > 0) {
          // Prevent submission if there are unanswered questions
          setPostTestNoAnswerMessage('Please answer all questions before submitting.');
          return;
        }
        setPostTestNoAnswerMessage('');

        const incorrectAnswers = [];
        postTestAnswers.forEach(answer => {
          const correctAnswer = preTestQuestions.find(question => question.id === answer.id)?.widget.answer;
          if (answer.answer !== correctAnswer) {
            incorrectAnswers.push(answer.id);
          }
        });

        if (incorrectAnswers.length > 0) {
          setHighlightedIncorrectAnswers(incorrectAnswers);
        }
        if (postTestAttempts === 2 && incorrectAnswers.length > 0) {
          setPostTestShowCorrectAnswerMessage(true);
          const correctAnswers = getCorrectAnswers();
          setHighlightedCorrectAnswers(correctAnswers);
        }
        if (postTestAttempts >= 3) {
          if (incorrectAnswers.length > 0) {
            setPostTestShowCorrectAnswerMessage(true);
            const correctAnswers = getCorrectAnswers();
            setHighlightedCorrectAnswers(correctAnswers);
            return;
          }
          handlePostTestCompleteSubmission();
        } else {
          handlePostTestCompleteSubmission();
        }
      }
    } else {
      if (data.answer) {
        setCurrentAnswer(data.answer);
      }
      setAnswer();
      savePartialAnswer();
      setCheckedState([]);
      getNextQuestion();
      setenableButton(false);
    }
  };

  useEffect(() => {
    if (currentQuestion?.widget?.choices?.length) {
      const currentCheckedState = currentAnswer?.answer?.map(item => {
        return {checkedChoice: item.checkedChoice, index: item.index};
      });
      setCheckedState(currentCheckedState);
      setCalculateCheckedBoxes(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionchanged]);

  useEffect(() => {
    if (calculateCheckedBoxes) {
      setChecked([]);
      if (checkedState?.length) {
        setChecked(checkedState?.map(item => item.index));
      }
      setDrawChoices(true);
      setCalculateCheckedBoxes(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculateCheckedBoxes]);

  const onValueChange = event => {
    setCurrentAnswer(event.target.value);
    setenableButton(true);
  };

  const save = () => {
    setAnswer();
    saveAnswer();
    updateAccountStat();
    setshowComplete(true);
    amplitude.getInstance().logEvent('cme-ended');
  };

  const saveAndQuit = () => {
    setAnswer();
    savePartialAnswer();
    setshowQuestionBlock(false);
  };

  const handlePostTestBack = () => {
    setshowQuestionBlock(false);
  };

  const handlePostTestSaveAndQuit = () => {
    savePostTestPartialAnswer(postTestAnswers);
    setshowQuestionBlock(false);
  };

  useEffect(() => {
    if (existingPostTestAnswers?.post_test_response?.length > 0) {
      const latestResponse =
        existingPostTestAnswers.post_test_response[existingPostTestAnswers.post_test_response.length - 1];
      // Initialize selected options and answers
      const initialSelectedOptions = {};
      const initialPostTestAnswers = [];

      latestResponse?.forEach(answer => {
        initialSelectedOptions[answer.id] = answer.answer;
        initialPostTestAnswers.push({
          id: answer.id,
          question: answer.question,
          answer: answer.answer,
        });
      });

      setSelectedPostTestOptions(initialSelectedOptions);
      setPostTestAnswers(initialPostTestAnswers);
    }
  }, [existingPostTestAnswers]);

  useEffect(() => {
    if (drawChoices === true) {
      const choiceBlockBuilder = [];
      choiceBlockBuilder.push(
        <div>
          {currentQuestion.widget?.choices?.map((choice, index) => (
            <Form.Check
              className="choice"
              name="cme-options"
              type="checkbox"
              label={choice}
              value={choice}
              id={index}
              // reset chckedstate when you move back nd next answer is stored in currentAnswer - rebuild checked state from
              // checked={checkedState?.includes(index)}
              checked={checked?.includes(index)}
              onChange={e => handleOnChange(e, index)}
            />
          ))}
        </div>,
      );
      if (checked.length > 0) {
        setenableButton(true);
      } else {
        setenableButton(false);
      }
      setChoiceBlock(choiceBlockBuilder);
      setDrawChoices(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawChoices]);

  const radioBlock = [];
  for (let i = currentQuestion.widget?.min; i <= currentQuestion.widget?.max; i += 1) {
    radioBlock.push(
      <Form.Check
        custom
        name="cme-options"
        value={i}
        inline
        type="radio"
        label={i}
        id={i}
        checked={parseInt(currentAnswer.answer, 10) === i}
        onChange={e => onValueChange(e)}
      />,
    );
  }
  const getLabel = (value, min, max) => {
    if (value === min) return ' Disagree';
    if (value === max) return ' Agree';
    return '';
  };
  const getLowHighLabel = (value, min, max) => {
    if (value === min) return ' Low';
    if (value === max) return ' High';
    return '';
  };
  const getWidget = question => {
    if (freeCme) {
      if (surveySubmitted) {
        return (
          <Button onClick={() => downloadFreeCmeCertificate()}>
            Download Certificate <i className="fas fa-chevron-right" />
          </Button>
        );
      }
      if (
        postTestPassed
        // && isOpenFinalSurvey
      ) {
        return (
          <div>
            {question.map(surveyQuestion => {
              switch (surveyQuestion.widget?.type) {
                case 'slider_group':
                  return (
                    <div className="question-block" key={surveyQuestion.id}>
                      <PostTestQuestionHeadingWrapper>
                        <h6 className="question-heading">Question {surveyQuestion.id}</h6>
                      </PostTestQuestionHeadingWrapper>
                      <p>{surveyQuestion.question}</p>
                      {surveyQuestion.widget.questions.map(subQuestion => (
                        <div key={subQuestion.id} className="slider-group-item">
                          <p>
                            <strong>{subQuestion.question}</strong>
                          </p>
                          <Form.Group key={subQuestion.id} className="radio-form">
                            {Array.from({length: subQuestion.widget.max - subQuestion.widget.min + 1}, (_, i) => {
                              const value = subQuestion.widget.min + i;
                              return (
                                <>
                                  <Form.Check
                                    key={value}
                                    custom
                                    name={`radio-${surveyQuestion.id}-${subQuestion.id}`}
                                    value={value}
                                    inline
                                    type="radio"
                                    label={
                                      <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                        <span className="mt-2">{value}</span>
                                        <span style={{fontSize: '11px', color: 'grey'}}>
                                          <br />
                                          {/* {value === subQuestion?.widget?.mim ? 'Strongly Disagree' : ''} */}
                                          {getLabel(value, subQuestion?.widget?.min, subQuestion?.widget?.max)}
                                        </span>
                                      </div>
                                    }
                                    id={`choice-${surveyQuestion.id}-${subQuestion.id}-${value}`}
                                    checked={surveySelectedOptions[`${surveyQuestion.id}(${subQuestion.id})`] === value}
                                    onChange={() => {
                                      setSurveySelectedOptions(prev => ({
                                        ...prev,
                                        [`${surveyQuestion.id}(${subQuestion.id})`]: value,
                                      }));

                                      setSurveyAnswers(prevAnswers => {
                                        const updatedAnswers = [...prevAnswers];

                                        const mainQuestionIndex = updatedAnswers.findIndex(
                                          answer => answer.id === surveyQuestion.id,
                                        );

                                        if (mainQuestionIndex > -1) {
                                          // Main question exists, check if sub-question exists
                                          const subAnswers = [...updatedAnswers[mainQuestionIndex].answers];

                                          const subQuestionIndex = subAnswers.findIndex(
                                            ans => ans.id === subQuestion.id,
                                          );

                                          if (subQuestionIndex > -1) {
                                            // Update existing sub-question answer
                                            subAnswers[subQuestionIndex] = {
                                              id: subQuestion.id,
                                              question: subQuestion.question,
                                              answer: value,
                                            };
                                          } else {
                                            // Add new sub-question answer
                                            subAnswers.push({
                                              id: subQuestion.id,
                                              question: subQuestion.question,
                                              answer: value,
                                            });
                                          }

                                          // Update main question with new sub-answers
                                          updatedAnswers[mainQuestionIndex] = {
                                            ...updatedAnswers[mainQuestionIndex],
                                            answers: subAnswers,
                                          };
                                        } else {
                                          // If main question doesn't exist, add it with the first sub-question answer
                                          updatedAnswers.push({
                                            id: surveyQuestion.id,
                                            question: surveyQuestion.question,
                                            answers: [
                                              {
                                                id: subQuestion.id,
                                                question: subQuestion.question,
                                                answer: value,
                                              },
                                            ],
                                          });
                                        }

                                        return updatedAnswers;
                                      });
                                    }}
                                    style={{
                                      '&:checked': {
                                        backgroundColor: surveySelectedOptions[subQuestion.id] === value ? 'blue' : '',
                                        color: surveySelectedOptions[subQuestion.id] === value ? 'white' : '',
                                      },
                                    }}
                                  />
                                </>
                              );
                            })}
                          </Form.Group>
                        </div>
                      ))}
                    </div>
                  );

                case 'slider':
                  return (
                    <div className="question-block" key={surveyQuestion.id}>
                      <PostTestQuestionHeadingWrapper>
                        <h6 className="question-heading">Question {surveyQuestion.id}</h6>
                      </PostTestQuestionHeadingWrapper>
                      <p>{surveyQuestion?.question}</p>
                      <Form.Group key={surveyQuestion.id} className="radio-form">
                        {Array.from({length: surveyQuestion.widget.max - surveyQuestion.widget.min + 1}, (_, i) => {
                          const value = surveyQuestion.widget.min + i;
                          return (
                            <Form.Check
                              key={value}
                              custom
                              name={`radio-${surveyQuestion.id}`}
                              value={value}
                              inline
                              type="radio"
                              label={
                                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                  <span className="mt-2">{value}</span>
                                  <span style={{fontSize: '11px', color: 'grey'}}>
                                    <br />
                                    {getLowHighLabel(value, surveyQuestion?.widget?.min, surveyQuestion?.widget?.max)}
                                  </span>
                                </div>
                              }
                              id={`choice-${surveyQuestion.id}-${value}`}
                              checked={surveySelectedOptions[surveyQuestion.id] === value}
                              onChange={() => {
                                setSurveySelectedOptions(prev => ({
                                  ...prev,
                                  [surveyQuestion.id]: value,
                                }));
                                setSurveyAnswers(prevAnswers => {
                                  const updatedAnswers = [...prevAnswers];

                                  const answerIndex = updatedAnswers.findIndex(
                                    answer => answer.id === surveyQuestion.id,
                                  );

                                  if (answerIndex > -1) {
                                    updatedAnswers[answerIndex] = {
                                      id: surveyQuestion.id,
                                      question: surveyQuestion.question,
                                      answer: value,
                                    };
                                  } else {
                                    updatedAnswers.push({
                                      id: surveyQuestion.id,
                                      question: surveyQuestion.question,
                                      answer: value,
                                    });
                                  }

                                  return updatedAnswers;
                                });
                              }}
                              style={{
                                '&:checked': {
                                  backgroundColor: surveySelectedOptions[surveyQuestion.id] === value ? 'blue' : '',
                                  color: surveySelectedOptions[surveyQuestion.id] === value ? 'white' : '',
                                },
                              }}
                            />
                          );
                        })}
                      </Form.Group>
                    </div>
                  );

                case 'text':
                  return (
                    <div className="question-block" key={surveyQuestion.id}>
                      <PostTestQuestionHeadingWrapper>
                        <h6 className="question-heading">Question {surveyQuestion.id}</h6>
                      </PostTestQuestionHeadingWrapper>
                      <p>{surveyQuestion?.question}</p>
                      <Form.Group controlId={`note-${surveyQuestion.id}`}>
                        <Form.Control
                          as="textarea"
                          placeholder="Type your response here"
                          value={surveySelectedOptions[surveyQuestion.id] || ''}
                          onChange={e => {
                            const textValue = e.target.value;
                            setSurveySelectedOptions(prev => ({
                              ...prev,
                              [surveyQuestion.id]: textValue,
                            }));
                            setSurveyAnswers(prevAnswers => {
                              const updatedAnswers = [...prevAnswers];

                              const answerIndex = updatedAnswers.findIndex(answer => answer.id === surveyQuestion.id);

                              if (answerIndex > -1) {
                                updatedAnswers[answerIndex] = {
                                  id: surveyQuestion.id,
                                  question: surveyQuestion.question,
                                  answer: textValue,
                                };
                              } else {
                                updatedAnswers.push({
                                  id: surveyQuestion.id,
                                  question: surveyQuestion.question,
                                  answer: textValue,
                                });
                              }

                              return updatedAnswers;
                            });
                          }}
                          name={`answer-${surveyQuestion.id}`}
                          className="a_textArea"
                        />
                      </Form.Group>
                    </div>
                  );

                case 'choice':
                  return (
                    <div className="question-block" key={surveyQuestion.id}>
                      <PostTestQuestionHeadingWrapper>
                        <h6 className="question-heading">Question {surveyQuestion.id}</h6>
                      </PostTestQuestionHeadingWrapper>
                      <p>{surveyQuestion.question}</p>
                      {surveyQuestion.widget.multiple ? (
                        <Form.Group className="check-form" controlId={`checkbox-${surveyQuestion.id}`}>
                          {surveyQuestion.widget.choices.map(choice => (
                            <PostTestWrapper key={choice}>
                              <div className="checkbox-wrapper">
                                <input
                                  type="checkbox"
                                  value={choice}
                                  id={choice}
                                  checked={surveySelectedOptions[surveyQuestion.id]?.includes(choice)}
                                  name={choice}
                                  onChange={() => {
                                    setSurveySelectedOptions(prev => {
                                      const selectedChoices = prev[surveyQuestion.id] || [];

                                      const updatedChoices = selectedChoices.includes(choice)
                                        ? selectedChoices.filter(c => c !== choice)
                                        : [...selectedChoices, choice];

                                      const newState = {
                                        ...prev,
                                        [surveyQuestion.id]: updatedChoices,
                                      };

                                      setSurveyAnswers(prevAnswers => {
                                        const updatedAnswers = [...prevAnswers];

                                        const answerIndex = updatedAnswers.findIndex(
                                          answer => answer.id === surveyQuestion.id,
                                        );

                                        if (answerIndex > -1) {
                                          updatedAnswers[answerIndex] = {
                                            id: surveyQuestion.id,
                                            question: surveyQuestion.question,
                                            answer: updatedChoices,
                                          };
                                        } else {
                                          updatedAnswers.push({
                                            id: surveyQuestion.id,
                                            question: surveyQuestion.question,
                                            answer: updatedChoices,
                                          });
                                        }

                                        return updatedAnswers;
                                      });
                                      return newState;
                                    });
                                  }}
                                  className="custom-checkbox"
                                />
                                <label htmlFor={choice} className="custom-label">
                                  {choice}
                                </label>
                              </div>
                            </PostTestWrapper>
                          ))}
                        </Form.Group>
                      ) : (
                        <Form.Group className="check-form" controlId={`checkbox-${surveyQuestion.id}`}>
                          {surveyQuestion.widget.choices.map(choice => (
                            <PostTestWrapper key={choice}>
                              <div className="checkbox-wrapper">
                                <input
                                  type="checkbox"
                                  value={choice}
                                  id={choice}
                                  checked={surveySelectedOptions[surveyQuestion.id]?.includes(choice)}
                                  name={choice}
                                  onChange={() => {
                                    setSurveySelectedOptions(prev => ({
                                      ...prev,
                                      [surveyQuestion.id]: choice,
                                    }));
                                    setSurveyAnswers(prevAnswers => {
                                      const updatedAnswers = [...prevAnswers];

                                      const answerIndex = updatedAnswers.findIndex(
                                        answer => answer.id === surveyQuestion.id,
                                      );

                                      if (answerIndex > -1) {
                                        updatedAnswers[answerIndex] = {
                                          id: surveyQuestion.id,
                                          question: surveyQuestion.question,
                                          answer: choice,
                                        };
                                      } else {
                                        updatedAnswers.push({
                                          id: surveyQuestion.id,
                                          question: surveyQuestion.question,
                                          answer: choice,
                                        });
                                      }
                                      setHighlightedIncorrectAnswers(prevIncorrect =>
                                        prevIncorrect.filter(id => id !== surveyQuestion.id),
                                      );

                                      return updatedAnswers;
                                    });
                                  }}
                                  className="custom-checkbox"
                                />
                                <label htmlFor={choice} className="custom-label">
                                  {choice}
                                </label>
                              </div>
                            </PostTestWrapper>
                          ))}
                        </Form.Group>
                      )}
                    </div>
                  );
                default:
                  return null;
              }
            })}
          </div>
        );
      }
      return (
        <div>
          {question.map(pretestQuestion => {
            const isIncorrectAnswer = highlightedIncorrectAnswers.includes(pretestQuestion.id);
            return (
              <div className="question-block" key={pretestQuestion.id}>
                <PostTestQuestionHeadingWrapper>
                  <h6 className={`question-heading ${isIncorrectAnswer ? 'highlight-red' : ''}`}>
                    Question {pretestQuestion.id}
                  </h6>
                </PostTestQuestionHeadingWrapper>
                <p>{pretestQuestion.question}</p>
                <Form.Group className="check-form" type="checkbox" controlId={`checkbox-${pretestQuestion.id}`}>
                  {pretestQuestion.widget.choices.map(choice => (
                    <PostTestWrapper key={choice}>
                      <div className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          value={choice}
                          id={`choice-${pretestQuestion.id}-${choice}`}
                          checked={selectedPostTestOptions[pretestQuestion.id] === choice}
                          name={`choice-${pretestQuestion.id}-${choice}`}
                          onChange={() => {
                            setSelectedPostTestOptions(prev => ({
                              ...prev,
                              [pretestQuestion.id]: choice,
                            }));
                            setPostTestAnswers(prevAnswers => {
                              const updatedAnswers = [...prevAnswers];

                              const answerIndex = updatedAnswers.findIndex(answer => answer.id === pretestQuestion.id);

                              if (answerIndex > -1) {
                                updatedAnswers[answerIndex] = {
                                  id: pretestQuestion.id,
                                  question: pretestQuestion.question,
                                  answer: choice,
                                };
                              } else {
                                updatedAnswers.push({
                                  id: pretestQuestion.id,
                                  question: pretestQuestion.question,
                                  answer: choice,
                                });
                              }
                              setHighlightedIncorrectAnswers(prevIncorrect =>
                                prevIncorrect.filter(id => id !== pretestQuestion.id),
                              );

                              return updatedAnswers;
                            });
                          }}
                          className={`custom-checkbox ${
                            highlightedIncorrectAnswers.includes(pretestQuestion.id) &&
                            selectedPostTestOptions[pretestQuestion.id] === choice
                              ? 'incorrect'
                              : ''
                          }`}
                        />
                        <label
                          htmlFor={`choice-${pretestQuestion.id}-${choice}`}
                          className={`custom-label ${
                            highlightedCorrectAnswers.some(
                              correctAnswer =>
                                correctAnswer.id === pretestQuestion.id && correctAnswer.correctAnswer === choice,
                            )
                              ? 'correct-answer-text'
                              : ''
                          }`}
                        >
                          {choice}
                        </label>
                      </div>
                    </PostTestWrapper>
                  ))}
                </Form.Group>
                {pretestQuestion?.description && postTestAttempts ? (
                  <QuestionDescription>{pretestQuestion?.description}</QuestionDescription>
                ) : (
                  ''
                )}
              </div>
            );
          })}
        </div>
      );
    }

    switch (question.widget?.type) {
      case 'slider':
        return (
          <div className="question-block">
            <h6>Your progress</h6>
            <ProgressBar now={total} />
            <p>{question.question}</p>
            <small>Please rate your response by selecting a number</small>
            <Form.Group className="radio-form" controlId="radio" onChange={e => onValueChange(e)}>
              {radioBlock}
            </Form.Group>
          </div>
        );
      case 'text':
        return (
          <div className="question-block">
            <h6>Your progress</h6>
            <ProgressBar now={total} />
            <p>{question.question}</p>
            {!currentQuestion.last_question ? (
              <Disclaimer>
                Note: CME credits will be revoked if answers are later deemed unsatisfactory by our CME partner provider
              </Disclaimer>
            ) : (
              ''
            )}
            <Form.Group controlId="note">
              <Form.Control
                as="textarea"
                placeholder="Type your response here"
                value={currentAnswer.answer}
                onChange={e => changeInput(e.target.value)}
                minLength={question.widget.min_length}
                name="answer"
                ref={register({required: true})}
                className={classNames('a_textArea', {bgTextarea: inputAns})}
              />
              {question.widget.min_length && (
                <small className={inputRemain <= 0 ? 'd-none' : 'text-required'}>
                  Minimum&nbsp;
                  {currentAnswer.answer ? inputRemain : question.widget.min_length}
                  &nbsp;characters required
                </small>
              )}
            </Form.Group>
          </div>
        );
      case 'choice':
        return (
          <div className="question-block">
            <h6>Your progress</h6>
            <ProgressBar now={total} />
            <p>{question.question}</p>
            <small>Please check all that apply.</small>
            <Form.Group
              className="check-form"
              type="checkbox"
              controlId="checkbox"
              // onChange={index => handleOnChange(index)}
              onChange={() => {}}
            >
              {choiceBlock}
            </Form.Group>
          </div>
        );
      default:
        return '';
    }
  };

  let buttonText;
  if (freeCme) {
    buttonText = !postTestPassed ? 'Claim Credit' : 'Submit Answers';
  } else {
    buttonText = 'Next';
  }

  return (
    <QuestionDiv>
      {(() => {
        if (freeCme) {
          if (postTestPassed && showClaimCredit) {
            return <h5>CME: Final Survey</h5>;
          }
          return <h5>CME: Post-Test</h5>;
        }
        return <h5>CME Assessment</h5>;
      })()}
      {freeCme && !postTestPassed ? <small>Complete the Post-Test after watching the video.</small> : ''}
      {expanded && <h5 className="a_title">{videoTitle}</h5>}
      <Form onSubmit={handleSubmit(handleSubmitForm)}>
        {freeCme ? getWidget(postTestPassed ? postTestSurveyQuestions : preTestQuestions) : getWidget(currentQuestion)}
        {freeCme && postTestNoAnswerMessage ? <p>{postTestNoAnswerMessage}</p> : ''}

        {freeCme && !postTestPassed && (
          <p style={{color: 'rgba(255, 0, 0, 0.7)'}}>
            {postTestShowCorrectAnswerMessage ? (
              <>
                All attempts failed. Please choose the correct answer in{' '}
                <span style={{color: 'teal', fontWeight: 'bold'}}>TEAL</span> and click submit.
              </>
            ) : (
              ''
            )}
            ` `
          </p>
        )}
        {freeCme && !postTestPassed && !postTestShowCorrectAnswerMessage && (
          <PostTestAnswerStatusMessage>
            {postTestAttempts < 2
              ? `You have ${3 - postTestAttempts} attempts left to correct your answers before complete submission.`
              : `This is your last attempt to correct your answers before complete submission, please choose carefully.`}
          </PostTestAnswerStatusMessage>
        )}

        <div className="d-flex mt-4">
          {!currentQuestion.last_question ? (
            <Button
              variant="link"
              type="button"
              className="a_save_quit py-0"
              disabled={freeCme ? !enablePostTestSaveAndQuit : !enableButton}
              onClick={() => (freeCme ? handlePostTestSaveAndQuit() : saveAndQuit())}
            >
              {postTestPassed ? '' : 'Save and Quit'}
            </Button>
          ) : (
            ''
          )}

          <div className="ml-auto">
            {currentQuestion.id !== 1 && (
              <Button
                variant="outline-secondary"
                type="button"
                className="a_back"
                onClick={() => (freeCme ? handlePostTestBack() : getQuestionById())}
              >
                Back
              </Button>
            )}

            {!currentQuestion.last_question ? (
              <Button
                variant={inputRemain <= 0 || enableButton || freeCme ? 'primary' : 'secondary'}
                disabled={freeCme ? enableButton : !enableButton}
                type="submit"
                className="a_next"
                onClick={() => {}}
              >
                {buttonText}
              </Button>
            ) : (
              <Button variant="primary" type="button" className="a_save" onClick={() => save()}>
                Save
              </Button>
            )}
          </div>
        </div>
      </Form>
    </QuestionDiv>
  );
});

CME.propTypes = {
  expanded: PropTypes.bool,
};
CME.defaultProps = {
  expanded: false,
};

export default CME;
