import React, {useState, useEffect, forwardRef, useImperativeHandle, createRef, useMemo} from 'react';
import {Nav, Tab} from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import {useLocation} from 'react-router-dom';
import CME from '../CME';
import Notes from '../Notes';
import Transcript from '../Transcript';
import ChatGPT from '../ChatGPT';
import WatchNext from '../WatchNext';
import StyledVideoTab from './styles';
import {useStore} from '../../../../store';
import VideoTabContent from '../VideoTabContent';
import CMEIcon from '../../../../assets/icons/icon_CME.svg';
import VideoIcon from '../../../../assets/icons/icon_video.png';
import NoteIcon from '../../../../assets/icons/icon_note.png';
import TranscriptIcon from '../../../../assets/icons/icon_transcript.png';
import ChatGPTIcon from '../../../../assets/icons/icon_chatgpt.png';
import LocalStorageService from '../../../../utils/LocalStorageService';
import {useOnScreen} from '../../../../hooks/useOnScreen';
import CertificateQuiz from '../Certificate';
import CertificateIcon from '../../../../assets/icons/graduation-cap-sharp-solid.svg';

const VideoTab = forwardRef(({expanded, onTabSelected, videoTitle}, ref) => {
  const {
    courseLandingStore,
    conferenceLandingStore,
    playlistStore,
    cmeStore,
    videoStore,
    adsStore,
    accountStore,
    // certificateStore,
  } = useStore();
  const {course, getCourse} = courseLandingStore;
  const {conference, getConference} = conferenceLandingStore;
  const {playlist, getAllPlaylists} = playlistStore;
  const {cmeDetails} = cmeStore;
  const {video, freeCme} = videoStore;
  const {getAd, ads, userTrackingAds} = adsStore;
  const [watchNextTitle, setWatchNextTitle] = useState('Watch Next');

  const loggedInUser = LocalStorageService.getUser();
  let initialTab;
  if (loggedInUser) {
    initialTab = freeCme ? 'cme' : 'chatgpt';
  } else if (!loggedInUser && freeCme) {
    initialTab = 'cme';
  } else {
    initialTab = 'video';
  }
  const [tab, setTab] = useState(window.innerWidth > 768 ? initialTab : '');
  const {account, setFeaturePermission} = accountStore;

  useImperativeHandle(ref, () => ({
    setCMETab() {
      setTab('cme');
    },
  }));
  const setCertificateAccess = () => {
    return setFeaturePermission('certificate');
  };
  const selectTab = eventKey => {
    if (eventKey === tab && expanded) {
      onTabSelected(false);
    } else {
      onTabSelected(true);
    }
    setTab(eventKey);
  };
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const courseId = query.get('course_id');
  const conferenceId = query.get('conference_id');
  const playlistId = query.get('playlist_id');
  useEffect(() => {
    if (courseId) {
      if (course?.title) setWatchNextTitle(`Course '${course.title}'`);
      else {
        getCourse(courseId);
        setWatchNextTitle(course?.title);
      }
    } else if (conferenceId) {
      if (conference?.title) setWatchNextTitle(`Conference '${conference.title}'`);
      else {
        getConference(conferenceId);
        setWatchNextTitle(conference?.title);
      }
    } else if (playlistId) {
      if (!playlist || !playlist.length) getAllPlaylists('DESC', 'created_at');
      const currentPlaylist = playlist?.find(x => x.id === parseInt(playlistId, 10));
      if (currentPlaylist) setWatchNextTitle(`Playlist '${currentPlaylist?.name}'`);
    }
    // eslint-disable-next-line
  }, [course, courseId, getCourse, playlist, playlistId, getAllPlaylists, conference, conferenceId, getConference]);
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const handleClickAds = (link, id) => {
    userTrackingAds({
      ad_id: id,
      impression_type: 'click',
      impression_source: window.location.pathname,
      device: window.innerWidth <= 800 && window.innerHeight <= 600 ? 'mobile' : 'desktop',
    });
    if (link) {
      window.open(link);
    }
  };

  useEffect(() => {
    const setBannerAds = () => {
      return setFeaturePermission('banner_ads');
    };

    if (setBannerAds()) {
      getAd('video_page');
    }
  }, [account, setFeaturePermission, getAd]);

  const [ids, setIds] = useState([]);
  const adRef = useMemo(
    () => ads.map(() => createRef()),
    // eslint-disable-next-line
    [ads.join(',')],
  );
  useOnScreen(adRef, ids, setIds);

  useEffect(() => {
    ids.map(el =>
      userTrackingAds({
        ad_id: el,
        impression_type: 'view',
        impression_source: window.location.pathname,
        device: window.innerWidth <= 800 && window.innerHeight <= 600 ? 'mobile' : 'desktop',
      }),
    ); // eslint-disable-next-line
  }, [ids]);
  return (
    <StyledVideoTab className="video-tab">
      <ReactTooltip effect="solid" place="left" offset={{left: 12}} id="videoTabs" globalEventOff="click" />
      <Tab.Container
        className="w-100"
        id="video-tab-menu"
        defaultActiveKey={window.innerWidth > 768 ? initialTab : ''}
        transition={false}
        onSelect={selectTab}
        activeKey={tab}
      >
        <div className={`d-flex flex-lg-row flex-column ${ads?.length > 0 ? 'has-ads' : ''}`}>
          <Tab.Content className={tab ? classNames({expanded}) : ''}>
            {(account?.access?.banner_ads || !account?.subscription?.is_premium) && ads?.length > 0 && (
              <div className="ads-container">
                {ads?.map(
                  (el, i) =>
                    i < 2 && (
                      <div
                        onClick={() => handleClickAds(el.link, el.id)}
                        onKeyDown={() => handleClickAds(el.link, el.id)}
                        role="button"
                        tabIndex={i}
                        ref={adRef[i]}
                        key={el.id}
                        id={el.id}
                      >
                        <img src={el.ad_asset} alt="" />
                      </div>
                    ),
                )}
              </div>
            )}
            <Tab.Pane eventKey="video">
              <VideoTabContent title={watchNextTitle}>
                <WatchNext />
              </VideoTabContent>
            </Tab.Pane>
            <Tab.Pane eventKey="cme">
              <VideoTabContent title={video.title}>
                <CME videoTitle={videoTitle} />
              </VideoTabContent>
            </Tab.Pane>
            <Tab.Pane eventKey="note">
              <VideoTabContent title="Notes">
                <Notes />
              </VideoTabContent>
            </Tab.Pane>
            <Tab.Pane eventKey="transcript">
              <VideoTabContent title="Transcript">
                <Transcript />
              </VideoTabContent>
            </Tab.Pane>

            <Tab.Pane eventKey="chatgpt">
              <VideoTabContent title="ChatGPT">
                <ChatGPT />
              </VideoTabContent>
            </Tab.Pane>
            {/* //certificate */}
            <Tab.Pane eventKey="certificate">
              <VideoTabContent title={video.title}>
                <CertificateQuiz />
              </VideoTabContent>
            </Tab.Pane>
          </Tab.Content>
          <Nav
            className="d-flex justify-content-lg-start justify-content-around px-5 px-lg-0 flex-lg-column a_videoTabs"
            data-tut="videotutorial__videotab"
          >
            {loggedInUser ? (
              <Nav.Item>
                <Nav.Link className="a_chatgptTabLink" eventKey="chatgpt">
                  <span className="new-tag">New</span>
                  {expanded && tab === 'chatgpt' ? (
                    <span key="chatgpt_active_tab_button">
                      <img src={ChatGPTIcon} alt="chatgpt" />
                    </span>
                  ) : (
                    <span key="chatgpt_inactive_tab_button">
                      <img src={ChatGPTIcon} data-tip="ChatGPT" data-for="videoTabs" alt="chatgpt" />
                    </span>
                  )}
                </Nav.Link>
              </Nav.Item>
            ) : null}
            <Nav.Item>
              <Nav.Link className="a_videoListTabLink" eventKey="video">
                {expanded && tab === 'video' ? (
                  <span key="video_active_tab_button">
                    <img src={VideoIcon} alt="watch next" />
                  </span>
                ) : (
                  <span key="video_inactive_tab_button">
                    <img src={VideoIcon} data-tip="Watch Next" data-for="videoTabs" alt="watch next" />
                  </span>
                )}
              </Nav.Link>
            </Nav.Item>
            {cmeDetails && (
              <Nav.Item>
                <Nav.Link className="a_cmeTabLink" eventKey="cme">
                  {expanded && tab === 'cme' ? (
                    <span key="cme_active_tab_button">
                      <img src={CMEIcon} width={20} alt="cme" />
                    </span>
                  ) : (
                    <span key="cme_inactive_tab_button">
                      <img
                        src={CMEIcon}
                        width={20}
                        className="icon-cme"
                        data-tip="CME"
                        data-for="videoTabs"
                        alt="cme"
                      />
                    </span>
                  )}
                </Nav.Link>
              </Nav.Item>
            )}
            {loggedInUser ? (
              <>
                <Nav.Item>
                  <Nav.Link className="a_noteTabLink" eventKey="note">
                    {expanded && tab === 'note' ? (
                      <span key="note_active_tab_button">
                        <img src={NoteIcon} alt="note" />
                      </span>
                    ) : (
                      <span key="note_inactive_tab_button">
                        <img src={NoteIcon} data-tip="Notes" data-for="videoTabs" alt="note" />
                      </span>
                    )}
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link className="a_transcriptionTabLink" eventKey="transcript">
                    {expanded && tab === 'transcript' ? (
                      <span key="transcript_active_tab_button">
                        <img src={TranscriptIcon} alt="transcript" />
                      </span>
                    ) : (
                      <span key="transcript_inactive_tab_button">
                        <img src={TranscriptIcon} data-tip="Transcript" data-for="videoTabs" alt="transcript" />
                      </span>
                    )}
                  </Nav.Link>
                </Nav.Item>
                {/* //certificate */}
                {setCertificateAccess() && process.env.REACT_APP_QUIZ_CERTIFICATE === 'true' ? (
                  <Nav.Item>
                    <Nav.Link className="a_transcriptionTabLink" eventKey="certificate">
                      {expanded && tab === 'certificate' ? (
                        <span key="transcript_active_tab_button">
                          <img src={CertificateIcon} alt="certificate" />
                        </span>
                      ) : (
                        <span key="transcript_inactive_tab_button">
                          <img src={CertificateIcon} data-tip="Certificate" data-for="videoTabs" alt="certificate" />
                        </span>
                      )}
                    </Nav.Link>
                  </Nav.Item>
                ) : (
                  ' '
                )}
              </>
            ) : null}
            {!loggedInUser && freeCme ? (
              <Nav.Item>
                <Nav.Link className="a_cmeTabLink" eventKey="cme">
                  {expanded && tab === 'cme' ? (
                    <span key="cme_active_tab_button">
                      <img src={CMEIcon} width={20} alt="cme" />
                    </span>
                  ) : (
                    <span key="cme_inactive_tab_button">
                      <img
                        src={CMEIcon}
                        width={20}
                        className="icon-cme"
                        data-tip="CME"
                        data-for="videoTabs"
                        alt="cme"
                      />
                    </span>
                  )}
                </Nav.Link>
              </Nav.Item>
            ) : null}
          </Nav>
        </div>
      </Tab.Container>
    </StyledVideoTab>
  );
});

VideoTab.propTypes = {
  expanded: PropTypes.bool,
  onTabSelected: PropTypes.func,
  videoTitle: PropTypes.string,
};
VideoTab.defaultProps = {
  expanded: false,
  onTabSelected: () => {},
  videoTitle: 'Title',
};

export default VideoTab;
