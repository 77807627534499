/* istanbul ignore next */
import React from 'react';
import {useHistory} from 'react-router-dom';
import TBDButton from '../TBDButton';
import DownloadArrow from '../../assets/icons/download-arrow.svg';
import {FinishAssessment, StartAssessment, DownloadCME, DownloadSpan, RetakeBtn} from './style';

export const StartAssessmentButton = ({watched, ...rest}: any) => {
  return (
    <StartAssessment {...rest} watched={watched}>
      <span>
        Start Assessment <i className="fas fa-chevron-right" />{' '}
      </span>
    </StartAssessment>
  );
};

export const FinishAssessmentButton = ({watched, ...rest}: any) => {
  return (
    <FinishAssessment {...rest}>
      <span>
        Finish Assessment <i className="fas fa-chevron-right" />{' '}
      </span>
    </FinishAssessment>
  );
};

export const StartPostTestAssessmentButton = ({watched, ...rest}: any) => {
  return (
    <StartAssessment {...rest} watched={watched}>
      <span>
        Start Post-Test <i className="fas fa-chevron-right" />{' '}
      </span>
    </StartAssessment>
  );
};

export const StartPostTestSurveyButton = ({watched, ...rest}: any) => {
  return (
    <StartAssessment {...rest} watched={watched}>
      <span>Continue Post Test </span>
    </StartAssessment>
  );
};

export const DownloadButton = ({onDownload, canDownload, isTrialUser, disabled, ...rest}: any) => {
  return (
    <>
      <DownloadCME type="button" className="a_download" onClick={onDownload} disabled={disabled} {...rest}>
        <TBDButton
          text="Not available during trial"
          subText="You can download your CME certificate after your trial"
          isShow={!canDownload}
          isTrial={isTrialUser}
        >
          <img src={DownloadArrow} className="icon-download" alt="download" />
          <DownloadSpan>Download</DownloadSpan>
        </TBDButton>
      </DownloadCME>
    </>
  );
};

export const RetakeButton = ({onRetake}: any) => {
  const location = useHistory();

  const onRetakeButtonClick = () => {
    onRetake();
    location.push(`/cme/in-progress`);
  };
  return (
    <RetakeBtn className="a_retake" onClick={onRetakeButtonClick} type="button">
      <span>
        Retake Assessment <i className="fas fa-chevron-right" />{' '}
      </span>
    </RetakeBtn>
  );
};

export default StartAssessmentButton;
