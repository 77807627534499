/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useState, useEffect} from 'react';
import {Button, Card, Col, Form, Row, Spinner} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {loadStripe} from '@stripe/stripe-js';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {observer} from 'mobx-react-lite';
import {useHistory} from 'react-router-dom';
import {useStore} from '../../../store';
import * as Api from '../../../api/subscription';
import {UpdateModal} from '../../Profile/elements/profile';
import {ButtonXLarge, Title1, SubTitle2, Body1, Caption} from '../../../utils/styles/typography';
import {amplitude} from '../../../utils/Amplitude';
import {
  Wrapper,
  TitleNumberDiv,
  TitleText,
  SectionTitle,
  VerticalDivider,
  FormCheckDiv,
  GiftCardExp,
  ShippingModal,
} from '../style';
import amazon from '../../../assets/icons/amazon-gc.png';
import visa from '../../../assets/icons/visa-gc.png';
import LocalStorageService from '../../../utils/LocalStorageService';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
type Props = {
  activeRegistrationStep: string,
  usecase: string,
  setPromoCodeFlow: (PromoCode | null) => void,
};

type CardFormProps = {
  isCardNum: boolean,
  isCardExp: booelan,
  isCardCvc: boolean,
  handleCardNum: () => void,
  handleCardExp: () => void,
  handleCardCVC: () => void,
  isCardErr: boolean,
  paymentError: string,
};

const CardForm = ({
  isCardNum,
  isCardExp,
  isCardCvc,
  handleCardNum,
  handleCardExp,
  handleCardCVC,
  isCardErr,
  paymentError,
}: CardFormProps) => (
  <div className="card-content">
    <SectionTitle>PAYMENT DETAILS</SectionTitle>
    <Row className="pt-2">
      <Form.Group className="mb-2" as={Col} xs={12}>
        <Form.Label>Card Number*</Form.Label>
        <CardNumberElement
          options={{showIcon: true, style: {base: {color: '#fff', fontWeight: 700}}}}
          id="payment-card-number-element"
          className={`form-control pt-2 ${isCardNum ? 'border-primary' : 'border-secondary'}`}
          onChange={handleCardNum}
        />
      </Form.Group>
    </Row>
    <Row>
      <Form.Group as={Col} xs={6}>
        <Form.Label>Expiration Date*</Form.Label>
        <CardExpiryElement
          options={{style: {base: {color: '#fff', fontWeight: 700}}}}
          id="payment-card-expiry-element"
          className={`form-control pt-2 ${isCardExp ? 'border-primary' : 'border-secondary'}`}
          onChange={handleCardExp}
        />
      </Form.Group>
      <Form.Group as={Col} xs={6}>
        <Form.Label>CVC Code*</Form.Label>
        <CardCvcElement
          options={{style: {base: {color: '#fff', fontWeight: 700}}}}
          id="payment-card-cvc-element"
          className={`form-control pt-2 bg-transparent ${isCardCvc ? 'border-primary' : 'border-secondary'}`}
          onChange={handleCardCVC}
        />
      </Form.Group>
    </Row>
    {(!isCardNum || !isCardExp || !isCardCvc) && isCardErr ? (
      <Caption className="text-danger text-center">Invalid payment information. </Caption>
    ) : null}
    {paymentError && (
      <div className="text-danger mt-2" role="alert">
        {paymentError}
      </div>
    )}
  </div>
);

const PaymentDetail = observer(({setPromoCodeFlow, activeRegistrationStep, usecase}: Props) => {
  const location = useHistory();
  const [couponCode, setCouponCode] = useState('');
  const [promoCode, setPromoCode] = useState(null);
  const [promoCodeError, setPromoCodeError] = useState('');
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentError, setPaymentError] = useState('');
  const [isFormSaving, setFormSaving] = useState(false);
  const [isCardNum, setCardNum] = useState(false);
  const [isCardExp, setCardExp] = useState(false);
  const [isCardCvc, setCardCvc] = useState(false);
  const [isCardErr, setCardErr] = useState(false);
  const [collectAddress, setCollectAddress] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [mobilephone, setMobilephone] = useState('');
  const [isErrShipping, setErrShipping] = useState(false);
  const handlePaymentModalClose = () => setShowPaymentModal(false);
  const handleAddressModalOpen = () => setShowAddressModal(true);
  const [loading, setLoading] = useState(false);
  const handleAddressModalClose = () => {
    setShowAddressModal(false);
    setErrShipping(false);
  };
  const {handleSubmit} = useForm();
  const [useExistingPaymentMethod, setUseExistingPaymentMethod] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const {authStore, accountStore, registrationUIStore, subscriptionStore} = useStore();
  const {
    isSubscribing,
    subscription,
    paymentMethod,
    updateSubscriptionCompleted,
    setIsSkipTrial,
    isSkipTrial,
    retrySubscription,
    setPaymentMethod,
    startSubscribing,
    stopSubscribing,
    createSubscription,
    updatePaymentDetails,
    setPromoCodeObservables,
    getGiftCardPrices,
  } = subscriptionStore;
  const {user, selectedPrice, isSelectGiftCard, onSelectGiftCard, selectedGiftCard} = authStore;
  const {account, getAccount} = accountStore;
  const {payment_details} = account;
  const {currentCampaign} = registrationUIStore;

  const giftCardPrices = getGiftCardPrices(selectedPrice);
  let amazonGiftCard;
  let visaGiftCard;
  if (giftCardPrices.length) {
    amazonGiftCard = giftCardPrices.find(price => price.nickname?.toLowerCase()?.includes('amazon'));
    visaGiftCard = giftCardPrices.find(price => price.nickname?.toLowerCase()?.includes('visa'));
  }

  useEffect(() => {
    if (!selectedGiftCard && amazonGiftCard) {
      onSelectGiftCard(amazonGiftCard);
    }
  }, [amazonGiftCard, onSelectGiftCard, selectedGiftCard]);

  const resetCouponCode = () => {
    setCouponCode('');
    setPromoCode(null);
    setPromoCodeError(null);
    setCollectAddress(false);
    setShowAddressModal(false);
    setAddress('');
    setCity('');
    setState('');
    setZipcode('');
    setMobilephone('');
    setErrShipping(false);
  };

  const onCouponActivation = async coupon => {
    try {
      const response = await Api.checkPromoCode(coupon, selectedPrice.metadata.id, user.id || account.id);
      if (response.data.couponHistory.length > 0) {
        const couponId = response.data.dbCoupon.id;
        if (response.data.couponHistory.filter(v => v.coupon_id === couponId).length > 0) {
          setPromoCodeError('Invalid Coupon');
          setPromoCodeObservables(null);
          setPromoCodeFlow(null);
          return;
        }
      }
      let data = null;
      if (response?.data?.isCoupon) {
        data = {
          isJustCoupon: true,
          coupon: response?.data?.coupon || response?.data?.dbCoupon,
        };
      } else {
        data = response?.data?.promoCode;
      }

      if (response?.data?.dbCoupon?.collect_address) {
        setCollectAddress(true);
        setShowAddressModal(true);
      } else {
        setCollectAddress(false);
        setShowAddressModal(false);
      }

      if (response?.data?.dbCoupon?.trial_period_days === 0) {
        setIsSkipTrial(true);
      }
      setPromoCode(data);
      setPromoCodeObservables(data);
      setPromoCodeFlow(data);
      amplitude.getInstance().logEvent('coupon-activated-v3', {name: coupon});
    } catch (error) {
      setPromoCodeError('Invalid Coupon');
      setPromoCodeObservables(null);
    }
  };

  const onCouponChange = data => {
    setCouponCode(data.target.value);
    setCollectAddress(false);
    setPromoCodeError('');
  };

  const handlePaymentThatRequiresCustomerAction = (recievedSubscription, isRetry, rInvoice) => {
    const paymentMethodId = paymentMethod?.id;
    if (recievedSubscription && recievedSubscription.status === 'active') {
      // subscription is active, no customer actions required.
      return true;
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = rInvoice ? rInvoice?.payment_intent : recievedSubscription?.latest_invoice?.payment_intent;

    if (
      paymentIntent &&
      (paymentIntent?.status === 'requires_action' ||
        (isRetry === true && paymentIntent?.status === 'requires_payment_method'))
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then(({error}) => {
          if (error) throw error;
        });
    }
    // No customer action needed
    return true;
  };

  const handleRequiresPaymentMethod = recievedSubscription => {
    if (recievedSubscription?.status === 'active') {
      // subscription is active, no customer actions required.
      return;
    }
    if (recievedSubscription?.latest_invoice?.payment_intent?.status === 'requires_payment_method') {
      throw new Error('Your card was declined.');
    }
  };

  const handleRegistrationSubmit = async event => {
    // Block native form submission.
    event.preventDefault();
    setLoading(true);
    if (collectAddress && !(address && city && state && zipcode && mobilephone)) handleAddressModalOpen();
    else {
      try {
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          return;
        }
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardNumberElement = elements.getElement(CardNumberElement);
        startSubscribing();
        // Use your card Element with other Stripe.js APIs
        const {error, paymentMethod: pMethod} = await stripe.createPaymentMethod({
          type: 'card',
          card: cardNumberElement,
        });
        setPaymentMethod(pMethod);
        if (error) {
          throw error;
        }
        const latestInvoicePaymentIntentStatus = subscription?.latest_invoice?.payment_intent?.status;
        if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
          // Update the payment method and retry invoice payment
          const rInvoice = await retrySubscription();
          await handlePaymentThatRequiresCustomerAction(subscription, true, rInvoice);
          await handleRequiresPaymentMethod();
          stopSubscribing();
          return;
        }
        // Create the subscription
        const rSubscription = await createSubscription(isSkipTrial, address, state, city, zipcode, mobilephone);
        const uet_report_conversion = () => {
          window.uetq = window.uetq || [];
          window.uetq.push('event', 'Start Subscription', {});
        };
        await handlePaymentThatRequiresCustomerAction(rSubscription);
        await handleRequiresPaymentMethod(rSubscription);
        uet_report_conversion();
        stopSubscribing();
        if (usecase === 'accountsettings') {
          amplitude.getInstance().logEvent('upgrade-or-resubscribe-completed-v3');
          await getAccount();
          location.push('/profile/settings');
        } else {
          amplitude.getInstance().logEvent('registration-completed-v3');
          // setActiveStep('profession');
          const redirectUrl = LocalStorageService.getRedirectUrl();
          if (redirectUrl && redirectUrl !== 'undefined') {
            location.push(redirectUrl);
          } else {
            location.push('/');
          }
        }
      } catch (error) {
        stopSubscribing();
        setPaymentError(error && (error.message || (error.error && error.error.decline_code)));
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePaymentFormSubmit = async () => {
    setFormSaving(true);
    try {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardNumberElement = elements.getElement(CardNumberElement);
      startSubscribing();
      setPaymentError(null);

      // Use your card Element with other Stripe.js APIs
      const {error, paymentMethod: pMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
      });

      setPaymentMethod(pMethod);
      updatePaymentDetails();
      setFormSaving(false);
      handlePaymentModalClose();
      stopSubscribing();
      if (error) {
        throw error;
      }
    } catch (error) {
      setPaymentError(error && (error.message || (error.error && error.error.decline_code)));
    }
  };

  const handleSubmitWithExistingMethod = async event => {
    // Block native form submission.
    event.preventDefault();
    setLoading(true);
    if (collectAddress && !(address && city && state && zipcode && mobilephone)) handleAddressModalOpen();
    else {
      try {
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          return;
        }
        startSubscribing();
        const rSubscription = await createSubscription(isSkipTrial, address, state, city, zipcode, mobilephone);
        await handlePaymentThatRequiresCustomerAction(rSubscription);
        await handleRequiresPaymentMethod(rSubscription);
        await accountStore.getAccount();
        stopSubscribing();
        amplitude.getInstance().logEvent('upgrade-or-resubscribe-completed-v3');
        if (usecase === 'accountsettings') {
          location.push('/profile/settings');
        } else {
          location.push('/');
        }
      } catch (error) {
        stopSubscribing();
        setPaymentError('There was an error upgrading the subscription. Please contact support.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCardNum = num => {
    if (num.complete) setCardNum(true);
    else {
      setCardNum(false);
      if (num.error) setCardErr(true);
    }
  };

  const handleCardExp = exp => {
    if (exp.complete) setCardExp(true);
    else {
      setCardExp(false);
      if (exp.error) setCardErr(true);
    }
  };

  const handleCardCVC = cvc => {
    if (cvc.complete) setCardCvc(true);
    else {
      setCardCvc(false);
      if (cvc.error) setCardErr(true);
    }
  };

  const handleAddress = e => {
    if (e.target.value) setAddress(e.target.value);
    else setAddress('');
  };

  const handleCity = e => {
    if (e.target.value) setCity(e.target.value);
    else setCity('');
  };

  const handleState = e => {
    if (e.target.value) setState(e.target.value);
    else setState('');
  };

  const handleZipcode = e => {
    if (e.target.value) setZipcode(e.target.value);
    else setZipcode('');
  };

  const handlePhoneNum = e => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (e.target.value && regex.test(e.target.value)) {
      setMobilephone(e.target.value);
      if (e.target.value.length === 10) {
        setMobilephone(e.target.value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
      }
      if (e.target.value.length === 13) {
        setMobilephone(e.target.value.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
      }
    } else setMobilephone('');
  };

  useEffect(() => {
    if (usecase !== 'registration' && updateSubscriptionCompleted) {
      location.push('/profile/settings');
    }
  }, [updateSubscriptionCompleted, location, usecase]);

  // Insert coupon automatically if coming from campaign
  useEffect(() => {
    if (currentCampaign && currentCampaign.coupon) {
      setCouponCode(currentCampaign.coupon);
      onCouponActivation(currentCampaign.coupon);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCampaign]);

  useEffect(() => {
    if (payment_details?.card?.last4) {
      setUseExistingPaymentMethod(true);
    }
  }, [payment_details]);

  const renderSummaryCard = () => {
    return (
      <Card className="small-card mt-3 mb-3">
        <Card.Body className="ready-card">
          <div className="d-flex align-items-center" id="card-title">
            <TitleNumberDiv>
              <span>{usecase === 'accountsettings' ? 2 : 3}</span>
            </TitleNumberDiv>
            <TitleText>Payment Details</TitleText>
          </div>
        </Card.Body>
      </Card>
    );
  };

  return (
    <Wrapper>
      <Card className="mt-3" style={activeRegistrationStep === 'payment' ? {} : {display: 'none'}}>
        <Card.Body>
          <div className="d-flex align-items-center mb-4" id="card-title">
            <TitleNumberDiv>
              <span>3</span>
            </TitleNumberDiv>
            <TitleText>Payment Details</TitleText>
          </div>
          <Form onSubmit={useExistingPaymentMethod ? handleSubmitWithExistingMethod : handleRegistrationSubmit}>
            {useExistingPaymentMethod ? (
              <>
                <Row>
                  <Col>
                    <div className="mt-2">
                      <h6 className="font-weight-bold pb-2">Card</h6>
                      <i className="fa-brands fa-cc-visa" /> {`**** **** **** ${payment_details?.card?.last4}`}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Button
                      variant="secondary"
                      size="sm"
                      className="a_continueToPaymentPageButton mt-3"
                      id="update-payment-button"
                      onClick={() => setUseExistingPaymentMethod(false)}
                    >
                      Use another payment method
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <CardForm
                isCardNum={isCardNum}
                isCardExp={isCardExp}
                isCardCvc={isCardCvc}
                handleCardNum={handleCardNum}
                handleCardExp={handleCardExp}
                handleCardCVC={handleCardCVC}
                isCardErr={isCardErr}
                paymentError={paymentError}
              />
            )}
            <VerticalDivider />
            {isSelectGiftCard ? (
              <div className="card-content">
                <SectionTitle>SELECT GIFT CARD</SectionTitle>
                <FormCheckDiv>
                  <div className="content">
                    <input
                      type="radio"
                      name="gift-card"
                      id="amazon-gc"
                      // checked={selectedGiftCard?.id === amazonGiftCard?.id}
                      onClick={() => onSelectGiftCard(amazonGiftCard)}
                    />
                    <label htmlFor="amazon-gc" className="label-elm first">
                      <div className="label-div">
                        <span className="circle" />
                        <Body1>
                          <img src={amazon} alt="amazon" className="ml-2 mr-3" /> $
                          {Math.floor(amazonGiftCard?.unit_amount / 100)} Amazon gift card
                        </Body1>
                      </div>
                    </label>

                    <input
                      type="radio"
                      name="gift-card"
                      id="visa-gc"
                      // checked={selectedGiftCard?.id === visaGiftCard?.id}
                      onClick={() => onSelectGiftCard(visaGiftCard)}
                    />
                    <label htmlFor="visa-gc" className="label-elm second">
                      <div className="label-div">
                        <span className="circle" />
                        <Body1>
                          <img src={visa} alt="visa" className="ml-2 mr-3" /> $
                          {Math.floor(visaGiftCard?.unit_amount / 100)} Visa gift card
                        </Body1>
                      </div>
                    </label>
                  </div>
                </FormCheckDiv>
                <GiftCardExp>*Gift cards issued within 4-6 weeks of purchase</GiftCardExp>
              </div>
            ) : (
              <div className="card-content">
                <SectionTitle>COUPON CODE</SectionTitle>
                <Row className="align-items-center">
                  <Form.Group className="mt-2 mb-2 align-items-center" as={Col} xs={8}>
                    <Form.Control
                      type="text"
                      placeholder="Enter coupon code"
                      name="couponCode"
                      id="coupon-code"
                      value={couponCode}
                      className={`pl-3 text-white font-weight-bold bg-transparent ${
                        !promoCodeError && promoCode != null ? 'border-primary' : !promoCodeError && 'border-secondary'
                      } ${couponCode && promoCodeError && 'border-danger'}`}
                      onChange={onCouponChange}
                      isInvalid={promoCodeError}
                    />
                    {couponCode && !promoCodeError && promoCode != null ? (
                      <Caption className="text-success mt-1">
                        {promoCode?.coupon?.percent_off && promoCode?.coupon?.percent_off !== 0 && (
                          <span>{promoCode?.coupon?.percent_off}% OFF&nbsp;</span>
                        )}
                        Coupon activated
                      </Caption>
                    ) : null}
                    {couponCode ? (
                      <Form.Control.Feedback type="invalid">
                        {promoCodeError || 'Coupon Unavailable'}
                      </Form.Control.Feedback>
                    ) : null}
                  </Form.Group>
                  <div className="text-right" as={Col} xs={4}>
                    <ButtonXLarge
                      variant={!couponCode ? 'secondary' : 'primary'}
                      className="a_activateCouponCode w-auto"
                      id="activate-coupon-code"
                      disabled={!couponCode || isSubscribing}
                      onClick={
                        !(couponCode && (promoCode || promoCodeError))
                          ? () => onCouponActivation(couponCode)
                          : resetCouponCode
                      }
                    >
                      {couponCode && (promoCode || promoCodeError) ? 'Reset' : 'Activate'}
                    </ButtonXLarge>
                  </div>
                </Row>
              </div>
            )}

            <div className="button-div">
              <ButtonXLarge
                type="submit"
                variant={loading ? 'secondary' : 'primary'}
                className="a_continueToPaymentPageButton mt-3"
                id="continue-to-payment-page-button"
              >
                {loading ? 'Loading...' : 'Start Subscription'}
              </ButtonXLarge>
            </div>
          </Form>
        </Card.Body>
      </Card>
      {activeRegistrationStep !== 'payment' && renderSummaryCard()}
      {/* Update Modal for Payment Information */}
      <UpdateModal show={showPaymentModal} onHide={handlePaymentModalClose} className="paymentInfoModal">
        <UpdateModal.Header closeButton>Payment Information</UpdateModal.Header>
        <UpdateModal.Body>
          <Form onSubmit={handleSubmit(handlePaymentFormSubmit)}>
            <Row id="payment-form">
              <Form.Group as={Col} xs={6}>
                <Form.Label>
                  <small>Card Number*</small>
                </Form.Label>
                <CardNumberElement
                  options={{showIcon: true}}
                  id="payment-card-number-element"
                  className="form-control pt-2 bg-light rounded-pill"
                />
              </Form.Group>
              <Form.Group as={Col} xs={3}>
                <Form.Label>
                  <small>EXP*</small>
                </Form.Label>
                <CardExpiryElement
                  id="payment-card-expiry-element"
                  className="form-control pt-2 bg-light rounded-pill"
                  onChange={handleCardExp}
                />
              </Form.Group>
              <Form.Group as={Col} xs={3}>
                <Form.Label>
                  <small>CVC*</small>
                </Form.Label>
                <CardCvcElement
                  id="payment-card-cvc-element"
                  className="form-control pt-2 bg-light rounded-pill"
                  onChange={handleCardCVC}
                />
              </Form.Group>
            </Row>
            <div className="text-danger mt-2" role="alert">
              {paymentError || null}
            </div>
            <Form.Row>
              <div className="w-100 d-flex flex-row-reverse">
                <Button
                  type="submit"
                  variant="primary"
                  id="save-account-info"
                  className="a_saveAccountInfoButton"
                  disabled={isFormSaving}
                >
                  {isFormSaving ? (
                    <>
                      <Spinner className="mr-2" as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                      Saving..
                    </>
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Form.Row>
          </Form>
        </UpdateModal.Body>
      </UpdateModal>
      {/* Update Modal for Shipping Address Information */}
      <ShippingModal show={showAddressModal} onHide={handleAddressModalClose} className="addressInfoModal">
        <ShippingModal.Header>
          <Title1>Shipping Address</Title1>
        </ShippingModal.Header>
        <ShippingModal.Body>
          <Form className="mt-3">
            <Form.Row>
              <Col xs={12}>
                <div>
                  <Form.Group>
                    <SubTitle2 className="text-left mb-1">Address</SubTitle2>
                    <Form.Control
                      size="xs"
                      type="text"
                      name="shippingAddress"
                      id="shipping-address"
                      placeholder="Enter address"
                      value={address}
                      className={`pl-3 coupon-code bg-transparent text-white ${address && 'border-primary'}`}
                      onChange={e => handleAddress(e)}
                    />
                  </Form.Group>
                </div>
              </Col>
              <Form.Group as={Col} xs={6}>
                <SubTitle2 className="text-left mb-1">City</SubTitle2>
                <Form.Control
                  size="xs"
                  type="text"
                  name="shippingCity"
                  id="shipping-city"
                  placeholder="Enter city"
                  value={city}
                  className={`pl-3 coupon-code bg-transparent text-white ${city && 'border-primary'}`}
                  onChange={handleCity}
                />
              </Form.Group>
              <Form.Group as={Col} xs={6}>
                <SubTitle2 className="text-left mb-1">State</SubTitle2>
                <Form.Control
                  size="xs"
                  type="text"
                  name="shippingState"
                  id="shipping-state"
                  placeholder="Enter state"
                  value={state}
                  className={`pl-3 coupon-code bg-transparent text-white ${state && 'border-primary'}`}
                  onChange={handleState}
                />
              </Form.Group>
              <Form.Group as={Col} xs={6}>
                <SubTitle2 className="text-left mb-1">Zip Code</SubTitle2>
                <Form.Control
                  size="xs"
                  type="text"
                  name="shippingZipCode"
                  id="shipping-zip-code"
                  placeholder="12345"
                  value={zipcode}
                  className={`pl-3 coupon-code bg-transparent text-white ${zipcode && 'border-primary'}`}
                  onChange={handleZipcode}
                />
              </Form.Group>
              <Form.Group as={Col} xs={6}>
                <SubTitle2 className="text-left mb-1">Phone Number</SubTitle2>
                <Form.Control
                  size="xs"
                  type="text"
                  name="shippingPhoneNumber"
                  id="shipping-phone-number"
                  placeholder="555-555-5555"
                  value={mobilephone}
                  className={`pl-3 coupon-code bg-transparent text-white ${mobilephone && 'border-primary'}`}
                  onChange={handlePhoneNum}
                />
              </Form.Group>
            </Form.Row>
            <div className="text-right mt-2">
              <ButtonXLarge variant="secondary" className="w-auto" onClick={handleAddressModalClose}>
                Close
              </ButtonXLarge>
              <ButtonXLarge
                type="button"
                variant="basic"
                id="save-shipping-address-info"
                className={`a_saveShippingAddressButton w-auto ml-3 ${
                  address && city && state && zipcode && mobilephone ? 'btn-primary' : 'btn-secondary'
                }`}
                onClick={
                  address && city && state && zipcode && mobilephone
                    ? handleAddressModalClose
                    : () => setErrShipping(true)
                }
              >
                Submit
              </ButtonXLarge>
            </div>
            {isErrShipping ? (
              <Caption className="text-danger d-block text-center mt-3">Please complete all fields.</Caption>
            ) : null}
          </Form>
        </ShippingModal.Body>
      </ShippingModal>
    </Wrapper>
  );
});

export default props => (
  <Elements stripe={stripePromise}>
    <PaymentDetail {...props} />
  </Elements>
);
