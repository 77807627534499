/* eslint-disable react/no-danger */
import classNames from 'classnames';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Container, Form, Nav, OverlayTrigger, Row, Spinner, Table, Tooltip} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import {useForm} from 'react-hook-form';
import Moment from 'react-moment';
import {useHistory, useRouteMatch} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import AlertComponent from '../../components/AlertComponent';
import {
  DownloadButton,
  FinishAssessmentButton,
  RetakeButton,
  StartAssessmentButton,
  StartPostTestAssessmentButton,
  StartPostTestSurveyButton,
} from '../../components/AssessmentButton';
import {ActiveSmallBtn, DefaultSmallBtn} from '../../components/FollowButtons';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import PageBottom from '../../components/PageBottom';
import PlaylistPopover from '../../components/PlaylistPopover';
import Sidebar from '../../components/Sidebar';
import UpgradeModal from '../../components/UpgradeModal';
import VideoPlayer from '../../components/VideoPlayer';
import {useStore} from '../../store';
import {amplitude} from '../../utils/Amplitude';
import stringToURLSlug from '../../utils/helper';
import LocalStorageService from '../../utils/LocalStorageService';
import {Body1, Body2, SubTitle1} from '../../utils/styles/typography';
import ErrorModal from '../CME/Components/ErrorModal';
import CMEStatusInfo from './components/CME/CMEStatusInfo';
import VideoTab from './components/VideoTab';
import {UpdateModal} from '../Playlist/elements/playlist';
import {
  Cast,
  CitationSpan,
  CitationText,
  CMESection,
  CopyText,
  Credit,
  DescriptionItem,
  DescriptionText,
  Main,
  MessageModal,
  NavItem,
  SubTitleDiv,
  Tab,
  // SectionDiv,
  TitleDiv,
  VideoAssociations,
  VideoOutline,
} from './elements/video';
import VideoComments from '../../components/VideoComments';
import history from '../../history';
import {getWatchVideoCount} from '../../api/video';
import {EarnCmeButton} from '../../components/AssessmentButton/style';

const Video = observer(() => {
  const [sidetabExpanded, setSidetabExpanded] = useState(true);
  const [isAssessmentDisabled, setAssessmentDisabled] = useState(false);
  const [descriptionTab, setDescriptionTab] = useState('description');
  const match = useRouteMatch();

  const location = useHistory();
  const [showMessageModal, setShowMessageModal] = useState(false);
  const {
    videoStore,
    accountStore,
    cmeStore,
    expertStore,
    alertStore,
    messageStore,
    commentStore,
    adsStore,
  } = useStore();
  const childRef = useRef();
  const targetDivRef = useRef(null);

  const {getAd} = adsStore;

  const {
    video,
    videoLoading,
    playlist,
    videoPlayer,
    videoPlaying,
    transcript,
    watched,
    getTranscript,
    getVideo,
    getAllPlaylists,
    addToPlaylist,
    updatePlaylist,
    updateThumbsUp,
    toggleVideoBookmark,
    followOrganization,
    unFollowOrganization,
    followSpecialty,
    unFollowSpecialty,
    updateExpertsFollow,
    videoTimeExceeded,
    videoPause,
    rewatch,
    videoModeration,
    deleteVideo,
    videoPublishOrNot,
    setPreTestVideoId,
    freeCme,
    surveySubmitted,
    freeCmeCertificateData,
  } = videoStore;

  const {account, setFeaturePermission} = accountStore;

  const {message, setMessage, status, resetAlert} = alertStore;

  const {followExpert, unFollowExpert} = expertStore;
  const {
    answers,
    showComplete,
    cmeDetails,
    UserCme,
    setshowQuestionBlock,
    download,
    downloadMessage,
    resetDownloadMessage,
    retakeAssessment,
    setPreTestCme,
    preTestSubmitted,
    postTestPassed,
  } = cmeStore;
  const {listComments, commentCount} = commentStore;

  const loggedInUser = LocalStorageService.getUser();
  const getRemainingDays = (date: Date) =>
    date ? Math.floor((new Date(date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) : 0;

  const [bookmarkIcon, setBookmarkIcon] = useState(false);

  useEffect(() => {
    if (video?.bookmark?.length) setBookmarkIcon(true);
    else setBookmarkIcon(false);
  }, [video]);

  const [isShowBookmarkUpgradeModal, setShowBookmarkUpgradeModal] = useState(false);
  const [isShowLikesUpgradeModal, setShowLikesUpgradeModal] = useState(false);
  const [isShowPlaylistUpgradeModal, setShowPlaylistUpgradeModal] = useState(false);
  const [isShowFollowUpgradeModal, setShowFollowUpgradeModal] = useState(false);
  const [userState, setUserState] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [rejectText, setRejectText] = useState('');
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showDeleteVideoModal, setShowDeleteVideoModal] = useState(false);
  const UserDetails = localStorage.getItem('user');

  const [freeVideoLimitExceed, setFreeVideoLimitExceed] = useState(false);
  const [watchVideoCount, setWatchVideoCount] = useState(0);
  const [downloadLoader, setDownloadLoader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {id} = match.params;

  const watchCount = async () => {
    try {
      if (loggedInUser) {
        const response = await getWatchVideoCount();
        if (response?.data?.status) {
          setWatchVideoCount(response.data?.watchCount);
          if (
            response.data?.watchCount >= 5 &&
            video?.access_level !== 'Free' &&
            video?.access_level !== null &&
            !video?.isuploaded
          ) {
            setFreeVideoLimitExceed(true);
          } else {
            setFreeVideoLimitExceed(false);
          }
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (video.id === parseInt(id, 10)) {
      watchCount();
    } else {
      setFreeVideoLimitExceed(false);
    }
    // eslint-disable-next-line
  }, [video.id, id]);

  const amplitudeMediaEvent = () => {
    const eventProperties = {
      'video title': `${video?.title}`,
    };
    amplitude.getInstance().logEvent('video-page-viewed', eventProperties);
  };

  const handleClick = () => {
    const {current} = targetDivRef;
    if (current) {
      window.scrollTo({
        top: current.offsetTop,
        behavior: 'smooth', // Optional: Adds smooth scrolling animation
      });
    }
  };

  useEffect(() => {
    getVideo(id);
    amplitudeMediaEvent();
    listComments(id, () => {});
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (
      !video?.organizationCME ||
      getRemainingDays(video?.organizationCME?.withdraw_at) < 0 ||
      getRemainingDays(video?.organizationCME?.cme_expire_at) < 0
    ) {
      setAssessmentDisabled(true);
    } else {
      setAssessmentDisabled(false);
    }
    if (video?.lastWatchPoint) {
      const {hours, minutes, seconds} = video.lastWatchPoint;
      const lastWatch = (hours * 3600 || 0) + (minutes * 60 || 0) + (seconds || 0);
      videoPlayer?.currentTime(lastWatch);
    }
  }, [video, videoPlayer]);

  const start = location.location?.state?.start_time;
  useEffect(() => {
    if (start && videoPlayer?.mediainfo?.id === video?.brightcove_id) {
      videoPlayer?.play();
      videoPlayer?.currentTime(start);
    }

    return () => {
      videoPlayer?.play();
    };
  }, [videoPlaying, start, videoPlayer, video?.brightcove_id]);

  useEffect(() => {
    if (videoTimeExceeded) videoPlayer?.currentTime(0);
  }, [videoTimeExceeded, videoPlayer, rewatch]);

  useEffect(() => {
    if (videoPause) videoPlayer?.pause();
  }, [videoPause, videoPlayer]);

  useEffect(() => {
    if (!account?.subscription?.name?.includes('Basic') && !account?.subscription?.name?.includes('Free')) {
      setUserState(true);
      getAllPlaylists();
    }
  }, [account, getAllPlaylists]);

  useEffect(() => {
    getTranscript();
  }, [transcript, getTranscript]);

  const createAndAddToPlaylist = data => {
    addToPlaylist(video.id, data.listname, true);
    // setMessage(`"${video.title}" Added to Playlist`);
  };

  const updateExistingPlaylist = data => {
    updatePlaylist(video.id, data, true);
    // setMessage('Playlist has been updated!');
  };

  const copyLink = () => {
    navigator.clipboard.writeText(window.location.toString());
    setMessage('This link has been copied to your clipboard', 'success');
  };

  const likeDislike = type => {
    if (loggedInUser) {
      if (!account?.subscription?.name?.includes('Basic') && !account?.subscription?.name?.includes('Free')) {
        updateThumbsUp(video.id, type);
        amplitude.getInstance().logEvent(`video-${type}d`);
      } else setShowLikesUpgradeModal(true);
    } else location.push('/login');
  };

  const toggleBookmark = () => {
    if (loggedInUser) {
      if (!account?.subscription?.name?.includes('Basic') && !account?.subscription?.name?.includes('Free')) {
        toggleVideoBookmark(bookmarkIcon).then(() => {
          setBookmarkIcon(!bookmarkIcon);
        });
        amplitude.getInstance().logEvent('bookmarked');
      } else setShowBookmarkUpgradeModal(true);
    } else location.push('/login');
  };

  const gotoOrganization = org => {
    if (org?.display) {
      const slug = stringToURLSlug(org.name);
      location.push(`/organization/${org.id}/${slug}`);
    }
  };

  const gotoExperts = ex => {
    if (ex?.display) {
      const slug = stringToURLSlug(ex.name);
      location.push(`/experts/${ex.id}/${slug}`);
    }
  };

  const gotoSpecialty = sp => {
    if (sp?.id && sp?.display_name) {
      const slug = stringToURLSlug(sp.display_name);
      location.push(`/specialty/${sp.id}/${slug}`);
    }
  };

  const gotoCourses = c => {
    if (c.id && c.title) {
      const slug = stringToURLSlug(c.title);
      location.push(`/courses/${c.id}/${slug}`);
    }
  };

  const organizationFollowHandler = () => {
    const user = localStorage.getItem('user');
    if (user) {
      // if (!account?.subscription?.name?.includes('Basic') && !account?.subscription?.name?.includes('Free')) {
      if (video?.organization?.is_following) {
        unFollowOrganization(video?.organization.id);
        const eventProperties = {
          type: 'organization',
          source: 'video page',
          name: `${video?.organization.name}`,
        };
        amplitude.getInstance().logEvent('unfollow', eventProperties);
      } else {
        followOrganization(video?.organization.id);
        const eventProperties = {
          type: 'organization',
          source: 'video page',
          name: `${video?.organization.name}`,
        };
        amplitude.getInstance().logEvent('follow', eventProperties);
      }
      // } else setShowFollowUpgradeModal(true);
    } else {
      location.push('/login');
    }
  };

  const expertFollowHandler = (ex, index) => {
    const user = localStorage.getItem('user');
    if (user) {
      // if (!account?.subscription?.name?.includes('Basic') && !account?.subscription?.name?.includes('Free')) {
      if (ex?.is_following) {
        unFollowExpert(ex).then(() => updateExpertsFollow(false, index));
        const eventProperties = {
          type: 'expert',
          source: 'video page',
          name: `${ex.name}`,
        };
        amplitude.getInstance().logEvent('unfollow', eventProperties);
      } else {
        followExpert(ex).then(() => updateExpertsFollow(true, index));
        const eventProperties = {
          type: 'expert',
          source: 'video page',
          name: `${ex.name}`,
        };
        amplitude.getInstance().logEvent('follow', eventProperties);
      }
      // } else setShowFollowUpgradeModal(true);
    } else {
      location.push('/login');
    }
  };

  const specialtiesFollowHandler = sp => {
    const user = localStorage.getItem('user');
    if (user) {
      // if (!account?.subscription?.name?.includes('Basic') && !account?.subscription?.name?.includes('Free')) {
      if (sp?.is_following) {
        unFollowSpecialty(sp.id);
        const eventProperties = {
          type: `${sp.level}`,
          source: 'video page',
          name: `${sp.display_name}`,
        };
        amplitude.getInstance().logEvent('unfollow', eventProperties);
      } else {
        followSpecialty(sp.id);
        const eventProperties = {
          type: `${sp.level}`,
          source: 'video page',
          name: `${sp.display_name}`,
        };
        amplitude.getInstance().logEvent('follow', eventProperties);
      }
      // } else setShowFollowUpgradeModal(true);
    } else {
      location.push('/login');
    }
  };

  const compareSpecialties = (a, b) => {
    if (a.name === b.name) {
      return 0;
    }
    if (a.name > b.name) {
      return 1;
    }
    return -1;
  };

  const subIndex = video?.specialties?.length
    ? video.specialties.filter(sp => sp.level === 'specialty')?.sort(compareSpecialties).length
    : 0;

  // const specialtyFollowHandler = () => {
  //   const user = localStorage.getItem('user');
  //   if (user) {
  //     if (!account?.subscription?.name?.includes('Basic')) {
  //       if (video?.specialty?.is_following) {
  //         unFollowSpecialty(video?.specialty.id);
  //       } else {
  //         followSpecialty(video?.specialty.id);
  //       }
  //     } else setShowFollowUpgradeModal(true);
  //   } else {
  //     location.push('/login');
  //   }
  // };
  const openCMETab = () => {
    if (!sidetabExpanded) {
      setSidetabExpanded(true);
    }
    childRef.current.setCMETab();
  };

  const [dowloadLoader, setDowloadLoader] = useState(false);
  const [canDownload, setCanDownload] = useState(true);
  const [isTrialUser, setIsTrialUser] = useState(false);

  useEffect(() => {
    if (
      (!account.subscription ||
        (account.subscription.status === 'cancelled' && !account.subscription.name) ||
        account.subscription.status === 'trialing') &&
      !account.access
    )
      setCanDownload(false);
    else setCanDownload(true);
    setIsTrialUser(account.subscription?.status === 'trialing');
  }, [account]);

  const onDownload = () => {
    if (!dowloadLoader && canDownload) {
      setDowloadLoader(true);
      download([UserCme?.id]).then(() => {
        setDowloadLoader(false);
        amplitude.getInstance().logEvent('cme-downloaded');
      });
    }
  };

  const onRetake = () => {
    retakeAssessment(video?.organizationCME?.CMEs[0]?.id, video?.organizationCME?.id).then(() => {
      location.go(0);
    });
  };
  const VideoApprove = data => {
    videoModeration(video.id, data, () => {
      setShowApproveModal(false);
      setShowRejectModal(false);
    });
  };
  const publishVideo = isPublish => {
    videoPublishOrNot(video.id, isPublish, () => {
      setShowPublishModal(false);
    });
  };

  const videoDelete = () => {
    deleteVideo(video?.id, () => {
      setShowDeleteVideoModal(false);
      if (JSON.parse(UserDetails).role === 'admin') {
        history.push('/admin/users-videos');
      } else {
        history.go(-1);
      }
    });
  };

  const renderTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      CME Assessment will be available only after the video has been started.
    </Tooltip>
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const handleStartCme = () => {
    setPreTestCme(true);
    setPreTestVideoId(video.id);
  };

  const downloadFreeCmeCertificate = () => {
    if (surveySubmitted && !downloadLoader) {
      setDownloadLoader(true);
      download([freeCmeCertificateData]).then(() => {
        setDownloadLoader(false);
      });
    }
  };

  const renderCMEAssessmentButton = () => {
    if (video?.organizationCME?.CMEs[0]?.revoked_at) {
      return <RetakeButton onRetake={onRetake} />;
    }

    if (freeCme) {
      if (account?.id) {
        if (surveySubmitted) {
          return (
            <Button onClick={() => downloadFreeCmeCertificate()}>
              Download Certificate <i className="fas fa-chevron-right" />
            </Button>
          );
        }
        if (postTestPassed) {
          return (
            <StartPostTestSurveyButton
              id="start-exam-desc-button"
              size="sm"
              className="a_startExamInDescButton order-lg-0 order-1"
              onClick={() => {
                openCMETab();
                setshowQuestionBlock(true);
              }}
              disabled={isAssessmentDisabled}
              watched={watched}
            />
          );
        }
        if (preTestSubmitted) {
          return (
            <StartPostTestAssessmentButton
              id="start-exam-desc-button"
              size="sm"
              className="a_startExamInDescButton order-lg-0 order-1"
              onClick={() => {
                openCMETab();
                setshowQuestionBlock(true);
              }}
              disabled={isAssessmentDisabled}
              watched={watched}
              preTestSubmitted={preTestSubmitted}
            />
          );
        }
        return (
          <EarnCmeButton
            id="start-pretest-button"
            size="sm"
            className="a_startPretestButton order-lg-0 order-1"
            onClick={() => {
              handleStartCme();
            }}
            disabled={isAssessmentDisabled}
            watched={watched}
          >
            <span>Start CME</span>
          </EarnCmeButton>
        );
      }
      return (
        <EarnCmeButton
          id="start-pretest-button"
          size="sm"
          className="a_startPretestButton order-lg-0 order-1 hashed"
          disabled
          watched={watched}
        />
      );
    }

    if (showComplete) {
      return (
        <DownloadButton
          onDownload={onDownload}
          canDownload={canDownload}
          isTrial={isTrialUser}
          disabled={dowloadLoader || !UserCme?.id}
        />
      );
    }
    if (isAssessmentDisabled) {
      return null;
    }
    if (video?.organizationCME?.CMEs[0]?.status === 'In Progress' || answers.length > 0) {
      return (
        <FinishAssessmentButton
          id="start-exam-desc-button"
          size="sm"
          className="a_startExamInDescButton order-lg-0 order-1"
          onClick={() => {
            openCMETab();
            setshowQuestionBlock(true);
          }}
          disabled={isAssessmentDisabled}
          watched={watched}
        />
      );
    }
    if (watched) {
      return (
        <StartAssessmentButton
          id="start-exam-desc-button"
          size="sm"
          className="a_startExamInDescButton order-lg-0 order-1"
          onClick={() => openCMETab()}
          disabled={isAssessmentDisabled}
          watched={watched}
          answersLength={answers.length}
        />
      );
    }
    return (
      <OverlayTrigger placement="bottom" delay={{show: 250, hide: 400}} overlay={renderTooltip}>
        <StartAssessmentButton
          id="start-exam-desc-button"
          size="sm"
          className="a_startExamInDescButton order-lg-0 order-1"
          disabled={isAssessmentDisabled}
          watched={watched}
          answersLength={answers}
        />
      </OverlayTrigger>
    );
  };

  const handleModalClose = () => {
    resetDownloadMessage();
  };

  useEffect(() => {
    return () => resetDownloadMessage();
  }, [resetDownloadMessage]);
  const handleMessageModalShow = () => setShowMessageModal(true);

  useEffect(() => {
    const setBumperAds = () => {
      return setFeaturePermission('bumper_ads');
    };
    if (process.env.REACT_APP_SHOW_BUMPER === true) {
      if (setBumperAds()) {
        getAd('bumper');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, account]);

  const videoDescription = () => (
    <>
      <DescriptionText>
        {video?.long_description?.length ? (
          <p
            className="video-description"
            dangerouslySetInnerHTML={{
              __html: video.long_description.replace(/(\r\n|\n|\r)/g, '<br />'),
            }}
          />
        ) : (
          <></>
        )}
      </DescriptionText>
      <CitationText>
        {video?.experts?.map((s, i) => (
          <CitationSpan clickable={s.display} className="experts" role="presentation" onClick={() => gotoExperts(s)}>
            {i !== 0 ? `, ${s.name}` : s.name}
          </CitationSpan>
        ))}
      </CitationText>
      <CitationText>
        {video?.specialties?.filter(sp => sp?.level === 'specialty') ? (
          <div>
            <b>Specialty: </b>
            {video?.specialties
              ?.filter(sp => sp?.level === 'specialty')
              ?.sort(compareSpecialties)
              ?.map((s, i) => (
                <CitationSpan
                  clickable={s.display}
                  className="specialties"
                  role="presentation"
                  onClick={() => gotoSpecialty(s)}
                >
                  {i !== 0 ? `, ${s.name}` : s.name}
                </CitationSpan>
              ))}
          </div>
        ) : null}
      </CitationText>
      <CitationText>
        {video?.specialties?.filter(sp => sp?.level === 'subspecialty').length ? (
          <div>
            <b>Subspecialty: </b>
            {video?.specialties
              ?.filter(sp => sp?.level === 'subspecialty')
              ?.sort(compareSpecialties)
              ?.map((s, i) => (
                <CitationSpan
                  clickable={s.display}
                  className="specialties"
                  role="presentation"
                  onClick={() => gotoSpecialty(s)}
                >
                  {i !== 0 ? `, ${s.name}` : s.name}
                </CitationSpan>
              ))}
          </div>
        ) : null}
      </CitationText>
      <CitationText>
        <CitationSpan
          clickable={video?.organization?.display}
          className="organization"
          role="presentation"
          onClick={() => gotoOrganization(video?.organization)}
        >
          {video?.organization?.name}
        </CitationSpan>
      </CitationText>
      {video?.isuploaded && (
        <>
          <CitationText>
            <div className="d-flex align-items-center">
              <div>
                Uploaded by : {video?.user?.first_name && `${video.user?.first_name}  ${video.user?.last_name} `}
              </div>

              {loggedInUser && loggedInUser.id !== video?.uploader_id && (
                <div>
                  <Button
                    style={{background: 'none', backgroundColor: 'none', border: 'none'}}
                    onClick={() => {
                      handleMessageModalShow();
                    }}
                  >
                    <i className="fa fa-envelope-o" />
                  </Button>
                </div>
              )}
            </div>
          </CitationText>
        </>
      )}
      <DescriptionText>
        <p>
          {video.uploader_id && (video?.filmed_at || video.release_date) ? (
            <>
              Uploaded on:&nbsp;
              <Moment format="MM/DD/YYYY">{video?.filmed_at ? video?.filmed_at : video.release_date}</Moment>
            </>
          ) : (
            ''
          )}
          {(video?.release_date || video?.published_at) && video?.filmed_at ? <>&nbsp; | &nbsp;</> : ''}
          {(video?.release_date || video?.published_at) && !video.uploader_id ? (
            <>
              Published:&nbsp;
              <Moment format="MM/YYYY">{video?.release_date || video?.published_at}</Moment>
            </>
          ) : (
            ''
          )}
        </p>
      </DescriptionText>
      {video?.chapterization_by && <Credit>Chapterization by: {video?.chapterization_by}</Credit>}
      {video?.description_by && <Credit>Description by: {video?.description_by}</Credit>}
      {video?.organization?.name === 'Mayo Clinic' ? (
        <CopyText>
          * Utilization of this Mayo Clinic online (enduring materials) course does not indicate nor guarantee
          competence or proficiency in the performance of any procedures which may be in this course.
        </CopyText>
      ) : (
        <></>
      )}
      {video?.content_subtype?.name === 'Recorded Presentation' ? (
        <CopyText>** This video may contain materials that have been removed due to copyright restrictions.</CopyText>
      ) : (
        <></>
      )}

      <CopyText>
        <a
          className="a_disclaimer"
          href="https://www.giblib.com/support/translation-disclaimer"
          target="_blank"
          rel="noreferrer"
        >
          Translation Disclaimer
        </a>
      </CopyText>
    </>
  );

  const [file, setFile] = useState('');
  const [error, setError] = useState('');
  const handleMessageModalClose = () => {
    setShowMessageModal(false);
    setFile('');
    setError('');
  };
  const {register, handleSubmit} = useForm({});
  const {sendDirectMessage} = messageStore;

  const onSubmitMessageForm = value => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('content', value.content);
    formData.append('receiver_id', video.uploader_id);
    if (value.content || file) {
      sendDirectMessage(
        formData,
        () => {
          handleMessageModalClose();
          setFile('');
          setError('');
        },
        () => {
          setShowMessageModal(true);
          setError('Invalid file format');
        },
      );
    }
  };
  const changeHandler = event => {
    const fileObj = event.target.files[0];
    setFile(fileObj);
  };
  useEffect(() => {
    setError('');
  }, [file]);
  const renderVideo = () => {
    return video ? (
      <>
        <UpgradeModal
          access="bookmark"
          isShow={isShowBookmarkUpgradeModal}
          onHideModal={() => setShowBookmarkUpgradeModal(false)}
        />
        <UpgradeModal
          access="likes"
          isShow={isShowLikesUpgradeModal}
          onHideModal={() => setShowLikesUpgradeModal(false)}
        />
        <UpgradeModal
          access="bookmark"
          isShow={isShowFollowUpgradeModal}
          onHideModal={() => setShowFollowUpgradeModal(false)}
        />
        <div className="w-100">
          <VideoPlayer
            videoId={video?.brightcove_id}
            thumbnail={video?.thumbnail}
            freeVideoLimitExceed={freeVideoLimitExceed}
            watchVideoCount={watchVideoCount}
          />
        </div>
        <div className="flex-grow-1 description-panel">
          <Container className="px-4" fluid>
            <Row className="pb-3 pt-lg-3 pt-1 section">
              <Col lg={7}>
                <TitleDiv className="mb-2 title-div">{video?.title}</TitleDiv>
                {video?.courses?.map((s, i) => (
                  <SubTitleDiv className="courses" onClick={() => gotoCourses(s)}>
                    {i !== 0 ? `${s.title}` : s.title}
                  </SubTitleDiv>
                ))}
              </Col>

              {!video.isuploaded ||
              (video.status === 'APPROVED' && UserDetails && JSON.parse(UserDetails).id !== video.uploader_id) ? (
                <Col lg={5} className="d-flex justify-content-lg-end mt-lg-0 mt-2 border-lg-none">
                  {video.isuploaded &&
                    UserDetails &&
                    video.status === 'APPROVED' &&
                    JSON.parse(UserDetails).role === 'admin' && (
                      <Button
                        variant="basic"
                        className="validationButton  reject mr-auto"
                        onClick={() => setShowRejectModal(true)}
                        data-tip="Reject"
                        data-for="videoActions"
                      >
                        Reject
                      </Button>
                    )}
                  {loggedInUser ? (
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={props => (
                        <PlaylistPopover
                          props={props}
                          videoPlaylist={video?.playlist}
                          allPlaylist={playlist}
                          onExisting={updateExistingPlaylist}
                          onNew={createAndAddToPlaylist}
                          userState={userState}
                          showUpgradeModal={isShowPlaylistUpgradeModal}
                          onHideUpgradeModal={() => setShowPlaylistUpgradeModal(false)}
                        />
                      )}
                      rootClose
                    >
                      <Button
                        variant="basic"
                        className="a_playlist action-icon-button mr-4"
                        data-tip="Playlist"
                        data-for="videoActions"
                        onClick={() => setShowPlaylistUpgradeModal(true)}
                      >
                        <i className="fak fa-list-ul" />
                      </Button>
                    </OverlayTrigger>
                  ) : (
                    <Button
                      variant="basic"
                      className="a_playlist action-icon-button mr-4"
                      onClick={() => location.push('/login')}
                      data-tip="Playlist"
                      data-for="videoActions"
                    >
                      <i className="fak fa-list-ul" />
                    </Button>
                  )}

                  <Button
                    variant="basic"
                    onClick={() => toggleBookmark()}
                    className="a_bookmark action-icon-button mr-4"
                    data-tip="Bookmark"
                    data-for="videoActions"
                  >
                    {bookmarkIcon ? (
                      <span key="video_bookmark">
                        <i className="fas fa-bookmark" />
                      </span>
                    ) : (
                      <span key="video_bookmark_active">
                        <i className="far fa-bookmark" />
                      </span>
                    )}
                  </Button>

                  <Button
                    variant="basic"
                    className="a_thumbs-up action-icon-button mr-4"
                    onClick={() => likeDislike('like')}
                    data-tip="Like"
                    data-for="videoActions"
                  >
                    {video?.like?.thumbs_up_down === true ? (
                      <span key="video_like_active">
                        <i className="fa-thumbs-up fas" />
                      </span>
                    ) : (
                      <span key="video_like">
                        <i className="fa-thumbs-up far" />
                      </span>
                    )}
                  </Button>

                  <Button
                    variant="basic"
                    className="a_thumbs-down action-icon-button mr-4"
                    onClick={() => likeDislike('dislike')}
                    data-tip="Dislike"
                    data-for="videoActions"
                  >
                    {video?.like?.thumbs_up_down === false ? (
                      <span key="video_dislike_active">
                        <i className="fa-thumbs-down fas" />
                      </span>
                    ) : (
                      <span key="video_dislike">
                        <i className="fa-thumbs-down far" />
                      </span>
                    )}
                  </Button>
                  <Button
                    variant="basic"
                    className="thumbs-down mr-4 action-icon-button commentbtn"
                    onClick={handleClick}
                    data-tip="Comments"
                    data-for="videoActions"
                  >
                    <i className="far fa-comment-dots" />
                    {+commentCount ? <div className="commentCount">{commentCount}</div> : <></>}
                  </Button>
                  <Button
                    variant="basic"
                    className="thumbs-down a_share-square action-icon-button"
                    onClick={copyLink}
                    data-tip="Share"
                    data-for="videoActions"
                  >
                    <i className="far fa-share-square" />
                  </Button>
                </Col>
              ) : (
                UserDetails &&
                video.status === 'PENDING' &&
                JSON.parse(UserDetails).role === 'admin' && (
                  <Col lg={5} className="d-flex justify-content-lg-end mt-lg-0 mt-2 border-lg-none">
                    <Button
                      variant="basic"
                      className="validationButton approve "
                      onClick={() => setShowApproveModal(true)}
                      data-tip="Approve"
                      data-for="videoActions"
                    >
                      Approve
                    </Button>
                    <Button
                      variant="basic"
                      className="validationButton  reject"
                      onClick={() => setShowRejectModal(true)}
                      data-tip="Reject"
                      data-for="videoActions"
                    >
                      Reject
                    </Button>
                    {UserDetails &&
                      JSON.parse(UserDetails).role === 'admin' &&
                      JSON.parse(UserDetails).id === video.uploader_id && (
                        <Button
                          variant="basic"
                          className="validationButton  reject"
                          onClick={() => setShowDeleteVideoModal(true)}
                          data-tip="Delete"
                          data-for="videoActions"
                        >
                          Delete
                        </Button>
                      )}
                  </Col>
                )
              )}
              {UserDetails && JSON.parse(UserDetails).id === video.uploader_id && (
                <Col lg={6} className="d-flex justify-content-lg-end mt-lg-0 mt-2 border-lg-none">
                  {video.status === 'APPROVED' && (
                    <Button
                      variant="basic"
                      className="validationButton "
                      onClick={() => setShowPublishModal(true)}
                      data-tip={video.active ? 'Unpublish' : 'Publish'}
                      data-for="videoActions"
                    >
                      {video.active ? 'Unpublish' : 'Publish'}
                    </Button>
                  )}

                  {JSON.parse(UserDetails).role !== 'admin' && (
                    <Button
                      variant="basic"
                      className="validationButton  reject"
                      onClick={() => setShowDeleteVideoModal(true)}
                      data-tip="Delete"
                      data-for="videoActions"
                    >
                      Delete
                    </Button>
                  )}
                </Col>
              )}
              {video.outline && (
                <Col xs={12}>
                  <Tab>
                    <Container className="ml-0 pl-0">
                      <Nav className="w-100">
                        <NavItem className="nav-item">
                          <DescriptionItem
                            className={`nav-link ${descriptionTab === 'description' ? 'active' : ''}`}
                            onClick={() => setDescriptionTab('description')}
                          >
                            Description
                          </DescriptionItem>
                        </NavItem>
                        <NavItem className="nav-item">
                          <DescriptionItem
                            className={`nav-link ${descriptionTab === 'outline' ? 'active' : ''}`}
                            onClick={() => setDescriptionTab('outline')}
                          >
                            Outline
                          </DescriptionItem>
                        </NavItem>
                      </Nav>
                    </Container>
                  </Tab>
                </Col>
              )}
              <Col lg={!loggedInUser || !cmeDetails ? 12 : 9}>
                {!video.outline && (
                  <>
                    <hr />
                    {videoDescription()}
                  </>
                )}
                {descriptionTab === 'description' && video.outline && videoDescription()}
                {descriptionTab === 'outline' && video.outline && (
                  <VideoOutline
                    dangerouslySetInnerHTML={{
                      __html: video.outline,
                    }}
                  />
                )}
              </Col>
              {loggedInUser && cmeDetails && (
                <Col lg={{span: 3, order: 0}} xs={{span: 12, order: 'first'}}>
                  <CMESection className="d-flex flex-lg-column align-items-lg-end align-items-center justify-content-lg-start justify-content-between w-100">
                    <CMEStatusInfo
                      status={video?.organizationCME?.CMEs[0]?.status}
                      credit={cmeDetails?.credit_count}
                      answersLength={answers.length}
                      completed={showComplete}
                    />
                    {renderCMEAssessmentButton()}
                  </CMESection>
                </Col>
              )}
              <Col xs={12}>
                <hr />
              </Col>
            </Row>
            {video?.organization?.name === 'GIBLIB' && cmeDetails && (
              <Row>
                <Col xs={12} className="cme-disclosure section">
                  <SubTitle1 className="subtitle">{video.organizationCME?.cme_description?.title1}</SubTitle1>
                  <Body1>{video?.organizationCME?.cme_description?.description1}</Body1>
                  <Body1>
                    {video?.organizationCME?.learning_objectives?.map(item => (
                      <li className="learning-objectives">{item}</li>
                    ))}
                  </Body1>
                  <SubTitle1 className="subtitle">{video?.organizationCME?.cme_description?.title2}</SubTitle1>
                  {video?.experts?.map(item => (
                    <Cast>{item.name}</Cast>
                  ))}
                  <SubTitle1 className="subtitle">{video?.organizationCME?.cme_description?.title3}</SubTitle1>
                  <Body1>
                    {video.specialties
                      ?.filter(sp => sp.level === 'specialty')
                      ?.map((s, i) => (
                        <>{i !== 0 ? `, ${s.display_name}` : s.display_name}</>
                      ))}
                  </Body1>
                  <Body1>{video?.organizationCME?.cme_description?.description3}</Body1>
                  <SubTitle1 className="subtitle">{video?.organizationCME?.cme_description?.title4}</SubTitle1>
                  <img
                    src={video?.organizationCME?.cme_description?.image2}
                    alt="GIBLIB CME"
                    className="accreditation"
                  />
                  <Body1 className="description">{video?.organizationCME?.cme_description?.description4}</Body1>
                  <SubTitle1 className="subtitle">{video?.organizationCME?.cme_description?.title5}</SubTitle1>
                  <Body1>
                    {video?.organizationCME?.cme_description?.text1}
                    {cmeDetails?.credit_count}
                    <span className="font-italic">{video?.organizationCME?.cme_description?.text2}</span>
                    {video?.organizationCME?.cme_description?.text3}
                  </Body1>
                  {video?.organizationCME?.cme_description?.title10 && (
                    <SubTitle1 className="subtitle">{video?.organizationCME?.cme_description?.title10}</SubTitle1>
                  )}
                  {video?.organizationCME?.cme_description?.image3 && (
                    <img
                      src={video?.organizationCME?.cme_description?.image3}
                      alt="GIBLIB CME"
                      className="accreditation"
                    />
                  )}
                  {video?.organizationCME?.cme_description?.description5 && (
                    <Body1 className="description">{video?.organizationCME?.cme_description?.description5}</Body1>
                  )}
                  <SubTitle1 className="subtitle">{video?.organizationCME?.cme_description?.title6}</SubTitle1>
                  <Body1>{video?.organizationCME?.cme_description?.description6}</Body1>
                  <Body1 className="description">{video?.organizationCME?.cme_description?.description7}</Body1>
                  <Body1 className="description">
                    Release Date: <Moment format="MM/DD/YYYY">{video?.release_date}</Moment>
                  </Body1>
                  <Body1 className="description">
                    Expiration Date:{' '}
                    {video?.organizationCME?.cme_expire_at < video?.organizationCME?.withdraw_at ? (
                      <Moment format="MM/DD/YYYY">{video?.organizationCME?.cme_expire_at}</Moment>
                    ) : (
                      <Moment format="MM/DD/YYYY">
                        {video?.organizationCME?.withdraw_at
                          ? video?.organizationCME?.withdraw_at
                          : video?.organizationCME?.cme_expire_at}
                      </Moment>
                    )}
                  </Body1>
                  <SubTitle1 className="subtitle">{video?.organizationCME?.cme_description?.title7}</SubTitle1>
                  <Body1>
                    {video?.organizationCME?.cme_description?.description8}
                    <a
                      href={video?.organizationCME?.cme_description?.link1}
                      target="_blank"
                      rel="noreferrer"
                      className="text-white"
                    >
                      {' '}
                      {video?.organizationCME?.cme_description?.linkText1},
                    </a>
                    <a
                      href={video?.organizationCME?.cme_description?.link2}
                      target="_blank"
                      rel="noreferrer"
                      className="text-white"
                    >
                      {' '}
                      {video?.organizationCME?.cme_description?.linkText2},
                    </a>
                    <a
                      href={video?.organizationCME?.cme_description?.link3}
                      target="_blank"
                      rel="noreferrer"
                      className="text-white"
                    >
                      {' '}
                      {video?.organizationCME?.cme_description?.linkText3},
                    </a>
                    <a
                      href={video?.organizationCME?.cme_description?.link4}
                      target="_blank"
                      rel="noreferrer"
                      className="text-white"
                    >
                      {' '}
                      {video?.organizationCME?.cme_description?.linkText4}.
                    </a>
                  </Body1>
                  <SubTitle1 className="subtitle">{video?.organizationCME?.cme_description?.title9}</SubTitle1>
                  <Body2>
                    {cmeDetails?.bibliography?.map(item => (
                      <li className="bibliography">{item}</li>
                    ))}
                  </Body2>
                  <SubTitle1>Financial Disclosures</SubTitle1>
                  <Table bordered className="desktop text-white">
                    <thead>
                      <tr>
                        <td rowSpan={2}>
                          <SubTitle1 className="text-white">Name</SubTitle1>
                        </td>
                        <td rowSpan={2}>
                          <SubTitle1 className="text-white">Content Controlled As:</SubTitle1>
                        </td>
                        <td colSpan={2} className="financial-relationships">
                          <SubTitle1 className="text-white">Financial Relationships</SubTitle1>
                        </td>
                        <td rowSpan={2}>
                          <SubTitle1 className="text-white">Mitigated</SubTitle1>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <SubTitle1 className="text-white">With</SubTitle1>
                        </td>
                        <td>
                          <SubTitle1 className="text-white">What</SubTitle1>
                        </td>
                      </tr>
                    </thead>
                    <tbody borderless>
                      {cmeDetails?.cme_disclosure?.map(item => (
                        <tr>
                          <td>
                            <Body2>{item.expert.name}</Body2>
                          </td>
                          <td>
                            <Body2>{item.content_controlled_as}</Body2>
                          </td>
                          <td>
                            <Body2>
                              {item.financial_relationships === null ? 'None' : `${item.financial_relationships}`}
                            </Body2>
                          </td>
                          <td>
                            <Body2>
                              {item.financial_relationships === null ? 'None' : `${item.financial_relationships}`}
                            </Body2>
                          </td>
                          <td>
                            <Body2>{item.mitigated === null ? 'N/A' : item.mitigated}</Body2>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {cmeDetails?.cme_disclosure?.map(item => (
                    <Table borderless className="mobile text-white">
                      <tr>
                        <td>
                          <Body1>
                            <span className="header">Name: </span>
                            {item.expert.name}
                          </Body1>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Body1>
                            <span className="header">Content Controlled as: </span>
                            {item.content_controlled_as}
                          </Body1>
                        </td>
                      </tr>
                      <tr>
                        <td className="last">
                          <Body1>
                            <span className="header">Financial Relationships with/what: </span>
                            {item.financial_relationships === null ? 'None' : `${item.financial_relationships}`}
                          </Body1>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Body1>
                            <span className="header">Mitigated: </span>
                            {item.mitigated === null ? 'N/A' : item.mitigated}
                          </Body1>
                        </td>
                      </tr>
                    </Table>
                  ))}
                </Col>
              </Row>
            )}
            <Row className="section" ref={targetDivRef}>
              <VideoComments />
            </Row>
            <Row className="section">
              {video?.experts?.map(
                (s, i) =>
                  s.display && (
                    <Col xs={12}>
                      <VideoAssociations>
                        {s.thumbnail && s.display && <img className="avatar" src={s?.thumbnail} alt={s.name} />}
                        <div className="ml-3">
                          <h5 className="expert" role="presentation" onClick={() => gotoExperts(s)}>
                            {s.name}
                          </h5>
                          {loggedInUser && (
                            <>
                              {s?.is_following ? (
                                <ActiveSmallBtn
                                  variant="primary"
                                  id="follow-expert-link"
                                  size="sm"
                                  className="a_followExpertLink px-2 py-0 a_followed"
                                  onClick={() => expertFollowHandler(s, i)}
                                >
                                  <span className="following">
                                    <i className="fas fa-check mr-2" />
                                    Following
                                  </span>
                                  <span className="unfollow">
                                    <i className="fas fa-times mr-2" />
                                    Unfollow
                                  </span>
                                </ActiveSmallBtn>
                              ) : (
                                <DefaultSmallBtn
                                  variant="primary"
                                  id="follow-expert-link"
                                  size="sm"
                                  className="a_followExpertLink px-2 py-0"
                                  onClick={() => expertFollowHandler(s, i)}
                                >
                                  <i className="fas fa-plus mr-2" />
                                  Follow
                                </DefaultSmallBtn>
                              )}
                            </>
                          )}
                        </div>
                      </VideoAssociations>
                    </Col>
                  ),
              )}
              {video?.specialties
                ?.filter(sp => sp.level === 'specialty')
                ?.sort(compareSpecialties)
                ?.map((s, i) => (
                  <Col xs={12}>
                    <VideoAssociations>
                      {s?.display_name && s?.id && <div id="specialty-avatar">{s.display_name.slice(0, 2)}</div>}
                      <div className="ml-3">
                        <h5 className="specialty" role="presentation" onClick={() => gotoSpecialty(s)}>
                          {s?.display_name}{' '}
                          <small>
                            <i>Specialty</i>
                          </small>
                        </h5>
                        {loggedInUser && s?.display_name && (
                          <>
                            {s?.is_following ? (
                              <ActiveSmallBtn
                                id="follow-specialty-link"
                                size="sm"
                                className="a_followSpecialtyLink px-2 py-0 a_followed"
                                onClick={() => specialtiesFollowHandler(s, i)}
                              >
                                <span className="following">
                                  <i className="fas fa-check mr-2" />
                                  Following
                                </span>
                                <span className="unfollow">
                                  <i className="fas fa-times mr-2" />
                                  Unfollow
                                </span>
                              </ActiveSmallBtn>
                            ) : (
                              <DefaultSmallBtn
                                variant="primary"
                                id="follow-specialty-link"
                                size="sm"
                                className="a_followSpecialtyLink px-2 py-0"
                                onClick={() => specialtiesFollowHandler(s, i)}
                              >
                                <i className="fas fa-plus mr-2" />
                                Follow
                              </DefaultSmallBtn>
                            )}
                          </>
                        )}
                      </div>
                    </VideoAssociations>
                  </Col>
                ))}
              {video?.specialties
                ?.filter(sp => sp.level === 'subspecialty')
                ?.sort(compareSpecialties)
                ?.map((s, i) => (
                  <Col xs={12}>
                    <VideoAssociations>
                      {s?.display_name && s?.id && <div id="specialty-avatar">{s.display_name.slice(0, 2)}</div>}
                      <div className="ml-3">
                        <h5 className="specialty" role="presentation" onClick={() => gotoSpecialty(s)}>
                          {s?.display_name}{' '}
                          <small>
                            <i>Subspecialty</i>
                          </small>
                        </h5>
                        {loggedInUser && s?.display_name && (
                          <>
                            {s?.is_following ? (
                              <ActiveSmallBtn
                                id="follow-specialty-link"
                                size="sm"
                                className="a_followSpecialtyLink px-2 py-0 a_followed"
                                onClick={() => specialtiesFollowHandler(s, subIndex + i)}
                              >
                                <span className="following">
                                  <i className="fas fa-check mr-2" />
                                  Following
                                </span>
                                <span className="unfollow">
                                  <i className="fas fa-times mr-2" />
                                  Unfollow
                                </span>
                              </ActiveSmallBtn>
                            ) : (
                              <DefaultSmallBtn
                                variant="primary"
                                id="follow-specialty-link"
                                size="sm"
                                className="a_followSpecialtyLink px-2 py-0"
                                onClick={() => specialtiesFollowHandler(s, subIndex + i)}
                              >
                                <i className="fas fa-plus mr-2" />
                                Follow
                              </DefaultSmallBtn>
                            )}
                          </>
                        )}
                      </div>
                    </VideoAssociations>
                  </Col>
                ))}
              <Col xs={12}>
                {video?.organization && (
                  <VideoAssociations>
                    {video?.organization?.thumbnail && video?.organization?.display && (
                      <img
                        className="avatar"
                        src={video?.organization?.thumbnail}
                        alt={video?.organization?.name || 'avatar icon'}
                      />
                    )}
                    <div className="ml-3">
                      <h5
                        className="organization"
                        role="presentation"
                        onClick={() => gotoOrganization(video?.organization)}
                      >
                        {video?.organization?.name}
                      </h5>
                      {loggedInUser && (
                        <>
                          {video?.organization?.is_following ? (
                            <ActiveSmallBtn
                              id="follow-organisation-link"
                              size="sm"
                              className="a_followOrganisationLink px-2 py-0 a_followed"
                              onClick={() => organizationFollowHandler()}
                            >
                              <span className="following">
                                <i className="fas fa-check mr-2" />
                                Following
                              </span>
                              <span className="unfollow">
                                <i className="fas fa-times mr-2" />
                                Unfollow
                              </span>
                            </ActiveSmallBtn>
                          ) : (
                            <DefaultSmallBtn
                              id="follow-organisation-link"
                              size="sm"
                              className="a_followOrganisationLink px-2 py-0 a_follow"
                              onClick={() => organizationFollowHandler()}
                            >
                              <i className="fas fa-plus mr-2" />
                              Follow
                            </DefaultSmallBtn>
                          )}
                        </>
                      )}
                    </div>
                  </VideoAssociations>
                )}
              </Col>
            </Row>
          </Container>

          {!videoLoading && <Footer />}
        </div>

        <VideoTab
          onTabSelected={setSidetabExpanded}
          expanded={sidetabExpanded}
          videoTitle={video?.title}
          ref={childRef}
        />
        {showApproveModal && (
          <UpdateModal show={showApproveModal} onHide={setShowApproveModal} className="deletePlaylistModal">
            <UpdateModal.Header closeButton>Approve Video</UpdateModal.Header>
            <UpdateModal.Body>
              <p>Are you sure you want to Approve {video?.title} ?.</p>

              <div className="w-100 d-flex flex-row-reverse">
                <Button
                  variant="basic"
                  className="validationButton approve "
                  onClick={() => VideoApprove({moderate: 'Approve', rejectText: ''})}
                >
                  Approve
                </Button>
              </div>
            </UpdateModal.Body>
          </UpdateModal>
        )}
        {showRejectModal && (
          <UpdateModal show={showRejectModal} onHide={setShowRejectModal} className="deletePlaylistModal">
            <UpdateModal.Header closeButton>Reject Video</UpdateModal.Header>
            <UpdateModal.Body>
              <p>Are you sure you want to Reject {video?.title} ?.</p>

              <div className="w-100">
                <textarea
                  type="text"
                  className="commentText"
                  placeholder="Reason for Rejection"
                  value={rejectText}
                  onChange={e => setRejectText(e.target.value)}
                />
              </div>
              <div className="w-100 d-flex flex-row-reverse">
                <Button
                  variant="basic"
                  disabled={!rejectText}
                  className="validationButton reject "
                  onClick={() => VideoApprove({moderate: 'Reject', rejectText})}
                >
                  Reject
                </Button>
              </div>
            </UpdateModal.Body>
          </UpdateModal>
        )}
        {showPublishModal && (
          <UpdateModal show={showPublishModal} onHide={setShowPublishModal} className="deletePlaylistModal">
            <UpdateModal.Header closeButton>{video.active ? 'Unpublish' : 'Publish'} Video</UpdateModal.Header>
            <UpdateModal.Body>
              <p>
                Are you sure you want to {video.active ? 'Unpublish' : 'Publish'} {video?.title} ?.
              </p>
              <div className="w-100 d-flex flex-row-reverse">
                <Button variant="basic" className="validationButton" onClick={() => publishVideo(!video.active)}>
                  {video.active ? 'Unpublish' : 'Publish'}
                </Button>
              </div>
            </UpdateModal.Body>
          </UpdateModal>
        )}

        {showDeleteVideoModal && (
          <UpdateModal show={showDeleteVideoModal} onHide={setShowDeleteVideoModal} className="deletePlaylistModal">
            <UpdateModal.Header closeButton>Delete Video</UpdateModal.Header>
            <UpdateModal.Body>
              <p>Are you sure you want to Delete {video?.title} ?.</p>
              <div className="w-100 d-flex flex-row-reverse">
                <Button variant="basic" className="validationButton reject " onClick={videoDelete}>
                  Delete
                </Button>
              </div>
            </UpdateModal.Body>
          </UpdateModal>
        )}

        <MessageModal show={showMessageModal} onHide={handleMessageModalClose}>
          <MessageModal.Header closeButton>
            <div>
              <h4 className="messageHead">SEND A NEW MESSAGE</h4>
            </div>
            {/* <div className="minimiseIcon">
              <i className="far fa-window-minimize" />
            </div>
            <div className="">
              <i className="fa-thin fa-arrow-up-right-and-arrow-down-left-from-center" />
            </div> */}
          </MessageModal.Header>
          <MessageModal.Body>
            <Form onSubmit={handleSubmit(onSubmitMessageForm)}>
              <div className="borderDiv">
                <Form.Label>
                  To :
                  {video.user ? (
                    <span className="toField">{` ${video.user.first_name}  ${video.user.last_name} `}</span>
                  ) : (
                    'not found'
                  )}
                </Form.Label>
              </div>
              <div className="textField">
                <textarea type="text" name="content" ref={register} />
              </div>
              <span>{file && <div>{file?.name}</div>}</span>
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <label htmlFor="fileUpload" className="iconBtn">
                  <i className="far fa-paperclip fa-flip-vertical" />
                  <input type="file" id="fileUpload" onChange={changeHandler} className="d-none" ref={register} />
                </label>
                <Button type="submit" className="sendBtn float-right">
                  Send
                </Button>
              </div>
            </Form>
            {error && (
              <div style={{justifyContent: 'flex-end'}} className="text-danger d-flex">
                {error}
              </div>
            )}
          </MessageModal.Body>
        </MessageModal>
      </>
    ) : (
      <div style={{margin: 'auto', textAlign: 'center'}}>
        This video isn&apos;t available.
        <br />
        The video you are looking for is not active right now or may have been removed.
      </div>
    );
  };

  return (
    <div id="videoPage" className="d-flex flex-column flex-fill">
      <Helmet>
        <title>{`${video?.title}`}</title>
        <meta name="twitter:card" content="summary" />
        <meta name="description" content={video?.long_description} />
        <meta
          name="twitter:image"
          content={
            video?.thumbnail ||
            'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png'
          }
        />
        <meta
          name="image"
          property="og:image"
          content={
            video?.thumbnail ||
            'https://s3.us-west-1.amazonaws.com/v4.0-assets/GBLB_Logo_Horizontal_Notag_White_FullColor.png'
          }
        />
        <meta name="og:title" content={`${video?.title}`} />
        <meta name="og:url" content={window.location.origin + window.location.pathname} />
        <script
          id="tracer-tag"
          adUnitId="3891"
          width="0"
          height="0"
          src="https://res.lassomarketing.io/scripts/sell-side-tag-min.js"
        />
      </Helmet>

      <Navbar />
      {message ? <AlertComponent message={message} status={status} click={() => resetAlert()} /> : null}
      <div className="d-flex">
        <Sidebar />
        <ReactTooltip effect="solid" place="top" id="videoActions" />
        <Main
          className={classNames('flex-column', {
            'sidetab-expanded': sidetabExpanded,
          })}
        >
          {videoLoading ? (
            <div className="spinner-wrapper w-100 h-100 d-flex align-items-center justify-content-center">
              <Spinner animation="grow" role="status" variant="secondary">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            renderVideo()
          )}
        </Main>
      </div>
      {downloadMessage?.length ? (
        <ErrorModal handleModalClose={handleModalClose} downloadMessage={downloadMessage} />
      ) : null}
      <PageBottom />
    </div>
  );
});

export default Video;
