import styled from 'styled-components';
import {Heading1} from '../../../utils/styles/typography';
import darkTheme from '../../../utils/styles/darkTheme';

export default styled.div`
  padding-bottom: 56.25%;
  width: 100%;
  position: relative;
  .spinner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
  }
  &.pausedContainer {
    position: absolute;
    top: 0;
  }
  .register-btn {
    background-color: ${darkTheme.surface};
    border-color: ${darkTheme.surface};
    font-weight: 700;
    &:hover {
      background-color: ${darkTheme.blue};
      border-color: ${darkTheme.blue};
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    }
  }
  .watch-btn {
    background-color: transparent;
    border: 1px solid #fff;
    font-weight: 700;
    border-radius: 5px;

    &:active,
    &:focus,
    &:focus-visible,
    &:focus-within {
      background: transparent !important;
    }
    &:hover {
      border: none;
      border-left: 1px solid #0dfff3;
      border-right: 1px solid #008aff;
      background-image: linear-gradient(90deg, #0dfff3, #008aff), linear-gradient(90deg, #0dfff3, #008aff);
      background-size: 100% 1px;
      background-position: 0 0, 0 100%;
      background-repeat: no-repeat;
    }
    .watch-icon {
      height: 12px;
      font-style: normal;
      font-weight: 900;
      font-size: 12px;
      line-height: 12px;
      align-items: center;
      text-align: center;
    }
  }
  .lock-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(90deg, rgba(0, 138, 255, 0.9) 0%, rgba(144, 67, 255, 0.9) 100%);
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.12))
      drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    // &::after {
    //   position: absolute;
    //   content: '';
    //   height: 100%;
    //   width: 100%;
    //   background-color: #000;
    //   opacity: 0.4;
    //   z-index: 0;
    // }
    .lock-link {
      position: relative;
      z-index: 1;
      height: 8.5rem;
      width: 8.5rem;
      border: 3px solid #fff;
      border-radius: 8.5rem;
      background-color: #a7a7a791;
      display: flex;
      align-items: center;
      justify-content: center;
      .lock-icon {
        color: #fff;
        font-size: 3.5rem;
      }
    }
  }

  .pre-test-form {
    padding: 40px;
  }

  .pre-test-question {
    width: 100%;
  }
  .pre-test-question-block {
    padding: 20px;
    height: 100%;
    width: 100%;
  }

  // .pre-test-block {
  //   padding: 20px;
  //   height: 100vh;
  //   width: 100%;
  // }

  .form-check-container label {
    font-size: 12px;
  }

  .pretest-lock-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100;
    background: linear-gradient(90deg, rgba(0, 138, 255, 0.9) 0%, rgba(144, 67, 255, 0.9) 100%);
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.12))
      drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
  }

  &.fullscreen-div {
    position: fixed;
    z-index: 10000;
    left: 0;
    height: 100%;
  }
  /* .question {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.25em;
    font-weight: bolder;
    text-align: center;
  }
  label {
    white-space: nowrap;
  }

  input {
    transform: scale(1.5);
    margin-right: 10px;
  } */
  .question {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2.25em;
    font-weight: bolder;
    text-align: center;
    margin: 0 auto;
    max-width: 70%;
  }

  label {
    white-space: nowrap;
    display: block;
    margin-bottom: 1em;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  input {
    transform: scale(1.5);
    margin-right: 10px;
    margin-left: 10px;
  }

  @media only screen and (min-width: 0px) and (max-width: 375px) {
    label {
      white-space: nowrap;
      display: block;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-bottom: 0.4rem;
      font-size: x-small;
    }
    .question {
      font-size: 0.7em;
    }

    input {
      transform: scale(0.7);
      margin-right: 1px;
      margin-left: 5px;
    }
    .pre-test-question h1 {
      font-size: 16px;
    }
    .form-check-container label {
      font-size: 12px;
    }
  }

  @media only screen and (min-width: 376px) and (max-width: 580px) {
    label {
      white-space: nowrap;
      display: block;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-bottom: 0.5rem;
      font-size: x-small;
    }

    input {
      transform: scale(0.9);
      margin-right: 10px;
    }

    .question {
      font-size: 0.8em;
    }
    .pre-test-question h1 {
      font-size: 18px;
    }
    .form-check-container label {
      font-size: 14px;
    }
  }
  @media only screen and (min-width: 581px) and (max-width: 790px) {
    .question {
      font-size: 1.3em;
    }

    label {
      white-space: normal;
      display: block;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 0.5rem;
      font-size: small;
    }

    input {
      transform: scale(1);
      margin-right: 5px;
    }
    .pre-test-question h1 {
      font-size: 20px;
    }
    .form-check-container label {
      font-size: 16px;
    }
  }

  @media only screen and (min-width: 791px) and (max-width: 1023px) {
    .question {
      font-size: 1.2em;
    }
    .pre-test-question h1 {
      font-size: 22px;
    }
    .form-check-container label {
      font-size: 16px;
    }
  }
  // @media only screen and (min-width: 1024px) and above {
  //   .pre-test-form form {
  //     padding: 0 1rem;
  //     width: 100%;
  //     height: 100%;
  //   }
  // }

  @media only screen and (min-width: 1024px) and (max-width: 1440px) {
    .pre-test-question h1 {
      font-size: 24px;
    }
    .form-check-container label {
      font-size: 18px;
    }
  }

  @media only screen and (min-width: 1441px) and (max-width: 1920px) {
    .pre-test-question h1 {
      font-size: 28px;
    }
    .form-check-container label {
      font-size: 1rem;
    }
  }
`;

export const Time = styled(Heading1)`
  line-height: 40px;
  text-align: center;
  color: #ffffff;
`;

export const Subtitle = styled.div`
  font-size: 18px;
  line-height: 38.5px;
  font-weight: 400;
`;

export const Videoplayer = styled.div`
  .on-fullscreen {
    display: none;
  }

  .video-screen {
    position: relative;
  }
`;

export const Skip = styled.button`
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  border: none;
  padding: 10px 25px;
  line-height: 14px;
  font-size: 14px;

  @media only screen and (min-width: 0px) and (max-width: 375px) {
    padding: 4px 6px;
    line-height: 6px;
    font-size: 10px;
  }

  @media only screen and (min-width: 376px) and (max-width: 479px) {
    padding: 6px 8px;
    line-height: 7px;
    font-size: 10px;
  }
`;

export const Bumper = styled.div`
  img {
    width: 100%;
  }
  .surveyImage {
    height: 60vh;
  }
`;

export const Sponsored = styled.div`
  position: absolute;
  bottom: 40px;
  height: 31px;
  display: flex;
  justify-content: right;
  width: 100%;
  padding-left: 10px;

  small {
    margin: auto 0;
    margin-right: auto;
  }
`;
export const SurveyForm = styled.form`
  color: #fff;

  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;

  label:hover {
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
`;

export const PreTestForm = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%; /* Responsive width */
  max-width: 100%; /* Prevent overflowing */
  max-height: 100%; /* Prevent overflowing */
  padding: 20px;
  padding-top: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 20px;

  /* Responsive scaling */
  font-size: clamp(0.9rem, 2vw, 1.2rem);
  overflow: hidden; /* Ensure no scrollbars */
`;

export const PreTestQuestionBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  // height: 100vh;
  padding: 20px;
  border-radius: 10px;
  // background-color: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;

  /* Dynamic font size scaling */
  font-size: clamp(1rem, 2vw, 1.5rem);
  line-height: 1.4;
  text-align: center;

  /* Ensure text visibility and wrapping */
  p {
    word-wrap: break-word;
    white-space: pre-wrap;
    margin: 0;
  }
`;

export const PreTestFormCheckContainer = styled.div`
  width: 100%; /* Fit container */
  max-height: 40vh; /* Fit within lock-wrapper */
  padding: 10px;
  border-radius: 10px;
  // background-color: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Add spacing between children */
`;

export const PreTestStyledLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px; /* Ensure consistent padding */
  // border: ${({selected}) => (selected ? '1px solid #007bff' : '1px solid rgba(0, 0, 0, 0.2)')};
  border-radius: 8px; /* Maintain rounded corners */
  background-color: ${({selected}) => (selected ? '#007bff' : 'rgba(0, 0, 0, 0)')};
  color: ${({selected}) => (selected ? '#fff' : '#fff')};
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.2s ease;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: ${({selected}) => (selected ? '#0056b3' : 'rgba(0, 0, 0, 0.2)')};
  }

  input {
    display: none; /* Hide the actual input */
  }

  @media (max-width: 480px) {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
    padding: 5px;
  }

  @media (max-width: 320px) {
    font-size: 0.8rem; /* Further scaling for ultra-small screens */
    padding: 3px;
  }
`;

export const SubmitButton = styled.button`
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 19px;
  cursor: pointer;
  display: block;
  margin: auto;

  &:hover {
    background-color: #2d80b9;
  }

  @media only screen and (min-width: 376px) and (max-width: 580px) {
    padding: 5px 7px;
    font-size: x-small;
    margin-top: 1.5em;
  }

  @media only screen and (min-width: 0px) and (max-width: 375px) {
    padding: 4px 6px;
    font-size: xx-small;
    margin-top: 1.5em;
  }
`;
